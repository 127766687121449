<template>
  <v-flex id="mainflex">
    <v-card class="rounded-card EDC-Designer-Row">
      <v-container class="EDC-Container">
        <v-row class="EDC-Row" justify="start">
          <v-col class="EDC-Col text-truncate" cols="10" align="left">
            <ul class="breadcrumb" style="display:inline-flex;">
              <!-- <li><a @click="savedata(true)">{{env_name}} </a></li>
              <li><a @click="savedata(true)"> {{parentdigramobj.diagram_name}} </a></li> -->
              <li><a @click="savedata(true)">{{stepType}} </a></li>
              <li>[{{stepSeqNo}}] <input type="text" v-model="stepname" :title="stepname" @blur="StepNameChanged()" :readonly="readonly" @keydown="preventKeyEvent" :size="nameTextboxSize"/></li>
            </ul>
          
            <span v-if='!readonly' style="font-weight:400;font-size:13px;margin-left:5px;" v-bind:class="{errorcls:is_error_occured , successcls:!is_error_occured}">{{'('+savedstatus + ')'}}</span>

            <span v-else style="color:rgba(0,0,0,0.54);font-weight:400;font-size:13px;margin-left:5px;">
            (Readonly Mode)</span>

            <span style="font-weight:400;font-size:13px;margin-left:5px;" :style="{'color': colorCode}">{{datasource_error||redoundoMsg}}</span>
          </v-col>
          <v-col cols="2" class="EDC-Col">
            
            <v-icon style="font-size:20px;float:right;padding-left:12px !important;" class="v-align" @click="stepper=1;savedata(true)" title='close'>far fa-times-circle</v-icon>

             <span class="" style="font-size:13px;float:right;margin-right:5px;" :style="{'color': colorCode}">
              <v-progress-circular :width="3" v-if="validateProgress" :size="20" color="blue" indeterminate></v-progress-circular>
              <showerrorlisttemplate :validation_response="validation_response" :stepType="stepType"
                @set-error="setError" @totalCount="totalCount" :errorListCount="errorListCount" :showprocess="validateProgress ? 'Y':'N'" style="margin-top:2px !important;margin-left:4px !important;" :showErrorPopUp="showErrorPopUp"></showerrorlisttemplate></span>

                <span style="float:right;" class="v-align" :style="{'color': colorCode}">
              <svgicon class="svg-icon-grid svg-fill-grid" name="validate"  style="float:right;margin-top:-20%;"  title="Validate Step" @click="validatestepdata" ></svgicon>
            </span>
            <!-- <svgicon class="svg-icon-grid svg-fill-grid" name="object_notes"  style="float:right;"  title="Add Step Description" @click="descriptionPanel = true"></svgicon> -->
          </v-col>
        </v-row>
        <v-row class="EDC-Row">
        <v-col class="EDC-Col" align="left">
          <edc-graph-toolbar style="padding-left:4px !important;" :redoCount="undoStackCount" :undoCount="redoStackCount" key="selection-desinger" @performUndoRedo="$emit('keyupHandler',$event)" @showDescription="descriptionPanel = true" @onSave="$emit('saveProcessDesigner')" :showNewVersion="false"></edc-graph-toolbar>
        </v-col>
      </v-row>
      </v-container>
    <v-container class="EDC-Container">
      <v-row class="EDC-Row">
        <v-col cols="12" class="EDC-Col">
          <v-stepper v-model="stepper" class="EDC-Stepper" non-linear @change="changeStepper">
              <v-stepper-header class="EDC-StepperHeader">
                <v-stepper-step step="1" :rules="selectTableRule" class="EDC-StepperStep" editable>
                  Table Selection
                </v-stepper-step>
                <v-divider class="EDC-StepperDivider"></v-divider>
                <v-stepper-step step="2" :rules="selectColumnRule" class="EDC-StepperStep" editable>
                  Columns Selection
                </v-stepper-step>
                <v-divider class="EDC-StepperDivider"></v-divider>
                <v-stepper-step step="3" class="EDC-StepperStep" :editable="stepType != mergeStepType">
                  Apply Filter
                </v-stepper-step>
                <v-divider class="EDC-StepperDivider"></v-divider>
                <v-stepper-step step="4" class="EDC-StepperStep" :editable="stepType != mergeStepType">
                  Worktable Options
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content class="EDC-Stepper-Content" step="1" elevation="0">
                  <v-row class="EDC-Row">
                    <v-col class="EDC-Col" :cols="tableOverlay">
                      <v-row class="EDC-Row">
                        <div :class="{'full-left-panel':showFullTablePanel,'half-left-panel':!showFullTablePanel}">
                          <edc-table-list :tableListLoader="tableListLoader" :tablelistobj="tablelistpanelproperties" 
                          @dragged-object="draggedObject" @performcollapse="perform_collapse" :steptablelist="steps_table_list" :readonly='readonly' style="width:100% !important;">
                          </edc-table-list>
                        </div>
                          <svgicon v-if="showFullTablePanel" class="svg-icon-mini svg-fill-mini svg-transform-left-icon" @click="toggleTablePanel('close')" name="colapse_v2" :original="true"></svgicon>
                          <svgicon v-else class="svg-icon-mini svg-fill-mini svg-transform-right-icon" @click="toggleTablePanel('close')" name="colapse_v2" :original="true"></svgicon>
                      </v-row>
                    </v-col>
                    <v-col class="EDC-Col" :cols="12-tableOverlay">
                      <div id="join_panel" ref="join_panel_ref">
                        <div id="selection_paper" style="border:1px solid gray !important;" ref="selection_paper">  
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="2" elevation="0" class="EDC-Stepper-Content">
                  <v-row class="EDC-Row">
                    <v-col :cols="columnOverlay" class="EDC-Col">
                      <v-row class="EDC-Row">
                        <div :class="{'full-left-column-panel':showFullColumnPanel,'half-left-column-panel':!showFullColumnPanel}">
                          <edc-column-list :tableListLoader="tableListLoader" :columnList="columnPanelList" 
                          @dragged-object="columnDraggedObject" @performcollapse="perform_collapse" :steptablelist="steps_table_list" :readonly='readonly' style="width:100% !important;">
                          </edc-column-list>
                        </div>
                        <svgicon v-if="showFullColumnPanel" class="svg-icon-mini svg-fill-mini svg-transform-left-icon" @click="toggleColumnPanel('close')" name="colapse_v2" :original="true"></svgicon>
                        <svgicon v-else class="svg-icon-mini svg-fill-mini svg-transform-right-icon" @click="toggleColumnPanel('close')" name="colapse_v2" :original="true"></svgicon>
                      </v-row>
                      
                    </v-col>
                    <v-col  class="EDC-Col" :cols="12-columnOverlay" id="col_panel" ref="col_panel_ref">
                      <v-row class="EDC-Row" v-if="!showExpressionOpt">
                        <v-col class="EDC-Col">
                          <v-row class="EDC-Row">
                            <v-col class="EDC-Col">
                              <table dense class="table table-striped table-bordered draggable_table" id="select_column_table":title="selection_error">
                                <thead :class="{highlight_tbl:selection_error}">
                                  <tr>
                                    <th>#</th>
                                    <th>Column</th>
                                    <th>Table</th>
                                    <th>Resulting Worktable Columns</th>
                                    <th>Report heading</th>
                                    <th v-if= "stepType === selectionStepType">Aggregate</th>
                                    <th>Order Type</th>
                                    <th v-if= "stepType != mergeStepType">Action</th> 
                                  </tr>
                                </thead>
                                <draggable v-model="columns_for_selection" :element="'tbody'">
                                  <tr v-for="(item, idx) in columns_for_selection" :key="idx" >
                                    <td class="handle" style="max-width: 28px;max-height:30px;">::</td>
                                    <td class="text-xs-left" v-model="item.column_name" :class="{highlight_tbl:item.error_list.column_name}" :title="item.error_list.column_name">
                                      {{item.column_name}}
                                    </td>
                                    <td class="text-xs-left" v-model="item.table_name" :class="{highlight_tbl:item.error_list.table_name}" :title="item.error_list.table_name">{{item.table_name}}</td>
                                    <td class="text-xs-left" :class="{highlight_tbl:item.error_list.alias_name}" :title="item.error_list.alias_name">
                                      <v-text-field :readonly='readonly' v-model="item.alias_name" placeholder="Alias name" @blur="aliasChange(item);savedata()" :title="item.alias_name" hide-details dense @focus="setLastAlias(item)"></v-text-field>
                                    </td>
                                    <td class="text-xs-left" :class="{highlight_tbl:item.error_list.report_alias_name}" :title="item.error_list.report_alias_name">
                                      <v-text-field dense :readonly='readonly' v-model="item.report_alias_name"  placeholder="Report Alias" @blur="savedata()" :title="item.report_alias_name" hide-details></v-text-field>
                                    </td>
                                    <td class="text-xs-left" style="width:120px;padding:0px;" v-if= "stepType === selectionStepType"> <v-autocomplete style="width:120px;padding:0px;" :items="aggregate_types" placeholder="Aggregate" item-text="name" item-value="id" v-model="item.aggregate" clearable dense hide-details :disabled='readonly'></v-autocomplete></td>
                                    <td>
                                      <toggle-button :disabled='readonly' v-model="item.order_type" :labels="{checked: 'DESC', unchecked: 'ASC'}":sync="true" :width="60" style="margin-bottom:0px !important;"/>
                                    </td>
                                    <td v-if= "stepType != mergeStepType">
                                      <v-icon small style="color:#494949;padding-right: 2px;cursor: pointer;" v-if="item.isExpression" @click="editExpression(item)" title="Edit Expression">edit</v-icon>
                                      <v-menu  :key="idx+'menu'" slot="activator" v-model="item.columnDeletionPopUp" left bottom :close-on-content-click="false">
                                        <template v-slot:activator="{ on,item }">
                                          <v-icon v-bind="item" v-on="on" slot="activator" small style="color:#494949" >fa-trash-alt</v-icon>
                                        </template>
                                        <v-card  style="width:500px !important;padding:0px !important;" class="EDC-Container">
                                          <v-toolbar class="EDC-Toolbar" dense elevation="1" style="border-radius:0px !important;height:32px !important;">
                                            <v-toolbar-title class="EDC-ToolbarTitle" style="border-radius:0px !important;height:32px !important;">Delete Options</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                            <svgicon  class="svg-icon-grid svg-fill-toolbar" name="cancel_v2"  :original="true" @click="item.columnDeletionPopUp=false" title="Close" style="margin-bottom:12px !important;"></svgicon>
                                          </v-toolbar>
                                            <v-row class="EDC-Row" style="padding:8px !important;">
                                              <v-col class="EDC-Col" cols="5">
                                                <v-radio-group hide-details v-model="columnDeletionType" dense>
                                                  <template v-for="(del,delindex) in columnDeletionOptions">
                                                    <v-row class="EDC-Row" :key="delindex">
                                                      <v-col class="EDC-Col">
                                                        <v-radio class="radioClass" :label="del.label"  :value="del.value"></v-radio>
                                                    </v-col>
                                                  </v-row>
                                                </template>
                                              </v-radio-group>
                                            </v-col>
                                            <v-col cols="1" class="EDC-Col">
                                              <v-divider style="margin:4px !important;" vertical></v-divider>
                                            </v-col>
                                            <v-col class="EDC-Col" cols="6" align="left">
                                                {{columnDeletionDescription}}
                                              </v-col>
                                            </v-row>
                                            <v-row class="EDC-Row">
                                              <v-col class="EDC-Col" cols="12" align="right">
                                                <vc-button type="button" @click.native="delete_selection_row(idx)" itemText="Delete"></vc-button>
                                                <vc-button type="button" @click.native="item.columnDeletionPopUp = false" itemText="Cancel"></vc-button>
                                              </v-col>
                                            </v-row>
                                        </v-card>
                                      </v-menu>
                                    </td> 
                                  </tr>
                                </draggable>
                              </table>
                            </v-col>
                          </v-row>
                          <v-row class="EDC-Row">
                            <v-col class="EDC-Col">
                              <span style="float:right;margin-right:1%;" v-if= "stepType != minusStepType">
                                <!-- <vc-button itemText="Add Expression" @click.native="showExpressionOpt=true" :disabled="readonly || !columnPanelList.length"></vc-button> -->
                              <label><input type="checkbox" v-model="distinct" :disabled="readonly"/>&nbsp;&nbsp;<strong>{{step_type_all}}</strong></label></span>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="EDC-Row" v-if="showExpressionOpt">
                        <v-col class="EDC-Col" style="min-height:48vh !important;">
                          <!-- <v-overlay :value="showExpressionOpt" absolute width="100"> -->
                            <v-row class="EDC-Row">
                              <v-col class="EDC-Col">
                                <sql-expression-builder @closeExpression="showExpressionOpt=false" :availableColumns="columnPanelList" 
                                @saveExpression="saveExpression" :newDraggedObj="expressionDraggedCol">
                                </sql-expression-builder>
                              </v-col>
                            </v-row>
                          <!-- </v-overlay> -->
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content class="EDC-Stepper-Content EDC-CriteriaStepper" step="3" elevation="0">
                  <v-row class="EDC-Row">
                    <new-edc-criteria key="criteria" :policy_list="policy_list" :showpolicies="showpolicies" :columns_for_filter="columns_for_filter" :column_name_list="column_name_list" 
                    @savedata="saveCriteria" @new="add_new_filter_row" @delete="delete_filter_row" :acceleratorObj="acceleratorObj" :new_columns_for_filter="main_filterUI" :secondaryfilter="secondary_filterUI" :readonly='readonly' :oldFilterObjDetails="oldFilterObjDetails" :redoUndoFilterObj="redoUndoFilterObj" @redoUndoFilterUpdate="redoUndoFilterUpdate"></new-edc-criteria>
                  </v-row>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <v-row class="EDC-Row">
                    <v-col class="EDC-Col">
                      <v-row class="EDC-Row">
                        <v-col class="EDC-Col" cols="3">
                          <v-card style="margin:8px !important;padding:8px !important;" elavation="1">
                              <v-row class="EDC-Row">
                                <v-col class="EDC-Col">
                                  <v-checkbox :disabled="readonly" :color="colorCode" label="Keep Table permanently" v-model="createReportingTable"
                                    class="radioClass" hide-details>
                                  </v-checkbox>
                                </v-col>
                              </v-row>
                              <v-row class="EDC-Row" v-if="createReportingTable">
                                <v-col class="EDC-Col">
                                    <v-row class="EDC-Row">
                                      <v-col class="EDC-Col">
                                        <!-- <v-text-field autocomplete="off" placeholder="Name Of The Resultant Worktable"  
                                        dense hide-details :readonly="readonly" v-model="worktableName"> 
                                      </v-text-field>-->
                                        <v-combobox label="Select/Add Reporting Table" :items="availableReportingTables"
                                        v-model="reportingTableName" item-text="object_name" item-value="object_name" :return-object="false">

                                        </v-combobox>
                                      </v-col>
                                    </v-row>
                                    <!-- <v-row class="EDC-Row">
                                      <v-col class="EDC-Col">
                                        <v-autocomplete v-model="reportingTableCreateOnDS" :disabled="readonly" label="Create Report Table On" hide-no-data dense
                                        :items="dsList" item-text="displayName" item-value="dsKeyName">
                                        </v-autocomplete>
                                      </v-col>
                                    </v-row> -->
                                </v-col>
                              </v-row>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
        </v-col>
      </v-row>
    </v-container>
      <div style="width:99%;margin-left:7px;">
        
          <!-- </draggable> -->
        
        </div>      
        <v-layout raw wrap style="margin-left:1%;margin-right:1%" v-show="stepType != mergeStepType">
            <!-- Embed criteria table here -->
            
        </v-layout>
      </v-card>
      <v-dialog v-model="tbl_dialog" persistent width="600">
        <v-flex style="height:100%">
          <v-card class="rounded-card" style="height:100%">
            <v-toolbar dark dense>
              <v-flex class="text-md-center">Select <b>{{dbl_click_tbl_cell_name}}</b> Table Columns
                <v-icon class="text-lg-left" @click="tbl_dialog = false" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
              </v-flex>
            </v-toolbar>
            
                <v-flex pa-2 xs12 style="height: 361px; overflow-y: auto;" id="step_detail_table">
                      <vc-data-table :data="columnDatatableList" @onSelectedRows="onSelectedCols">
                    
                      </vc-data-table>
                </v-flex>
                    <vc-button ml-4 mr-4 :color="outlineColor" style="border-radius:10px" @click.native="performselection()" :disabled='readonly' itemText="Save"></vc-button>
                
          </v-card>
        </v-flex>
      </v-dialog>
      <v-dialog v-model="join_dialog" persistent width="800" height="800">
        <v-flex>
          <v-card class="rounded-card">
            <v-toolbar dark dense>
             <v-flex class="text-md-center">Specify Join
               <v-icon class="text-lg-left" @click="close_join_dialog(true)" style="color:#dedede;height:22px;float:right">fa-times-circle</v-icon>
             </v-flex>
           </v-toolbar>
           <v-container grid-list-md align-content-space-around>
            <v-layout raw wrap>
              <!-- <v-flex xs4 style="font-weight: bold;margin:auto"><label :class="{highlight_tbl:jfrom_error}" :title="jfrom_error">{{join_from_table}}</label></v-flex> -->
              <v-flex xs4 style="font-weight: bold;margin:auto;text-align: left;margin-top:3%;">{{join_from_table}}</v-flex>
              <v-flex xs3 style="text-align: left;">
                <v-autocomplete :items="jtype_list" item-text="jtype_list" v-model="jtype" dense hide-details :disabled='readonly'></v-autocomplete>
              </v-flex>
              <!-- <v-flex xs4 style="font-weight: bold;;margin:auto"><label :class="{highlight_tbl:jto_error}" :title="jto_error">{{join_to_table}}</label></v-flex> -->
              <v-flex xs4 style="font-weight: bold;margin:auto;text-align: left;margin-top:3%;">{{join_to_table}}</v-flex>
              <v-flex xs1></v-flex>
            </v-layout>

            <v-layout raw wrap>
              <v-flex xs4>
                <v-autocomplete :items="new_jfrom_column_list" item-text="description" item-value ="column_name" hide-details
                label="Select From" rule="selectFromrule" v-model="jfrom" refs="el_jfrom" required clearable @input="JoinAcceleration" :disabled='readonly' dense>
              </v-autocomplete>
            </v-flex>
            <v-flex xs3>
              <v-autocomplete :items="operators"  item-text="name" item-value="id" v-model="join_opeator"  hide-details dense :disabled='readonly'></v-autocomplete>
            </v-flex>
            <v-flex xs4>
              <v-autocomplete :items="new_jto_column_list" ref="el_jto" item-text="description" item-value ="column_name" hide-details
              label="Select To" :rule="selectTorule" v-model="jto" required clearable dense :disabled='readonly'>
            </v-autocomplete>

          </v-flex>
          <v-flex xs1>
            <button v-if="add_join_button" @click="add_join(add_join_button)" title="Add join">
              <v-icon style="font-size:40px;" >add_box</v-icon> </button>
              <button v-else @click="add_join(add_join_button)" title="Add join">
                <v-icon style="font-size:40px;" >add_box</v-icon> </button>
              </v-flex>
              <!-- <v-flex xs3> <v-btn color="success" v-on:click="add_join()">Add</v-btn></v-flex> -->
            </v-layout >
            <v-layout raw wrap style="max-height:250px;overflow-y:auto;margin-top:2%;">
             <table border="0" class="table table-striped table-bordered table-fixed">
              <tbody>
                <tr v-for="(item, index) in join_json_for_link">
                  <td :class="{highlight_tbl:item.error_list.from_column}" :title="item.error_list.from_column" style="width:33%;text-align: left;">{{getDescriptionInCondition(item.from_column,'from')}}</td>
                  <td style="width:24%;text-align: left;">{{changeText[item.operator]}}</td>
                  <td :class="{highlight_tbl:item.error_list.to_column}" :title="item.error_list.to_column" style="width:33%;text-align: left;">{{getDescriptionInCondition(item.to_column,'to')}}</td>
                  <td>
                    <v-icon small class="mr-2" style="color:#494949" @click="update_join(item.from_column, item.operator, item.to_column, index)">fa-edit</v-icon>
                    <v-icon small style="color:#494949" @click="delete_join(index)">fa-trash-alt</v-icon>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-layout>
          <vc-button  @click.native="close_join_dialog()" :disabled="readonly" itemText="Submit"></vc-button>
        </v-container>
      </v-card>
    </v-flex>
  </v-dialog>
  <v-dialog v-model="descriptionPanel" width="1000" eager>
    <v-card>
      <rich-text-box style="backgroud:white" key="selectionRichTextBox" @DescriptionChange="DescriptionChange" :vueEditorObj="vueEditorObj">
        
      </rich-text-box>
      <v-card-actions>
        <v-spacer></v-spacer>
        <vc-button  itemText= "Close" @click.native="descriptionPanel = false">
                
              </vc-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <context-menu id="context-menu" ref="ctxMenu" v-if="!readonly">
    <li style="font-weight:bold" @mouseover="mouseOverEvent($event,'selection')" @mouseout="mouseOutEvent($event)" @click="contextMenuClicked('delete')" ref="eldelete" v-bind:class="{disabled : !isCellSelected}">Delete</li>
  </context-menu>

  <simplert :useRadius="true"
    :useIcon="true"
    ref="simplert" key="selectionSimplert">
  </simplert>

</v-flex>
</template>
<script>
const joint = require('jointjs')
import { shapes, dia } from 'jointjs'
import {COLOR_CODE,BTN_COLOR} from '@/data/macros.js'
import tablelistpanel from './../../views/tablelistpanel.vue'
import columnlistpanel from './../../views/columnlistpanel.vue'
import contextMenu from 'vue-context-menu'
import edcGraphToolbar from '@/views/edcGraph/edcGraphToolbar.vue'

import draggable from 'vuedraggable'
import moment from 'moment'
import Simplert from 'vue2-simplert'
import showerrorlisttemplate from './../../views/showerrorlisttemplate.vue'
import SqlExpressionBuilder from "@/views/expressionBuilder/expressionBuilder.vue"

import * as edcGraphConstantsObj from "../../methods/edcGraph/constants.js";
import * as edcAnonymousObj from  "../../methods/edcGraph/anonymousFunctions.js"
import * as edcLinkObj from "../../methods/edcGraph/links.js"
import * as rubberbandObj from "../../methods/edcGraph/rubberband.js"
import * as graphObj from "../../methods/edcGraph/graph.js"
import * as edcLinktoolObj from "../../methods/edcGraph/linktool.js"
import * as edcShapeObj from '../../methods/edcGraph/shapes.js'
import * as edcContextMenuObj from "../../methods/edcGraph/contextmenu.js"
import * as edcConstantObj from '../../methods/edcGraph/constants.js'
import * as edcCellsObj from '../../methods/edcGraph/cells.js'

import config from '../../config.json'

import {getDefaultLinkProperies,RestrictCyclicConnection,ValidateSourceTarget,ValidateOutgoingLink} from "../../methods/flowcharts/links.js"
import {validate_graph_data_for_selection,get_elements_ids_by_name} from "../../methods/flowcharts/graph.js"
import {JOIN_OPERATOR_ARRAY_JSON,JOIN_TYPE,AGGREGATE_FUNCTIONS, FLITER_JOIN_OPERATOR_ARRAY_JSON,GRAPH_TABLE_ERROR_MESSAGES} from '../../methods/flowcharts/constants.js'
import Sortable from 'sortablejs'
import {getTableList, getBulkTablesColumns,getColumnsMoreInfo,getRevisedColumnsWithDescription,getTableDescriptions} from "../../methods/agent_api_call.js"
export const DATE_TYPE_ARRAY = {'Database Date':'MM/DD/YYYY', 'JDE Julien': '', 'CCYYMMDD': 'CCYYMMDD', 'Century Only': 'CC', 'Year Only': 'YY', 'Month Only':'MM', 'YYDDD': 'YYDDD'};
import dateformat from './../../views/dateformat.vue'
import {validate_process_def_json} from "../../methods/call_validation.js"
import Criteria from '@/views/newcriteria.vue';
import {GRAPH_GRID_SIZE,GRID_COLOR} from "../../methods/flowcharts/constants.js"
import _ from 'lodash';
import {
  VALUE_TO_TEXT,VALIDATION_IN_RPOCESS_MESSAGE
} from "../../methods/flowcharts/constants.js";
import {PerformStepNameValidation} from "../../methods/flowcharts/graph.js"
import {
        CLIENT_SIDE,
        SERVER_SIDE
    } from '@/data/macros.js'
import dataTable from '@/views/dataTable/datatable.vue'
import {DEFAULT_DESCRIPTION,getColDescription} from "../../methods/flowcharts/commonFunc.js"
import RichTextBox from "@/views/richTextEditor/index.vue";
import { post as postToServer } from '@/methods/serverCall.js';

export default {
  components:{
    draggable,
    dateformat,
    'edc-table-list':tablelistpanel,
    showerrorlisttemplate,
    'new-edc-criteria':Criteria,
    'vc-data-table': dataTable,
    'edc-column-list':columnlistpanel,
    'rich-text-box':RichTextBox,
    'context-menu':contextMenu,
    'simplert':Simplert,
    'edc-graph-toolbar':edcGraphToolbar,
		'sql-expression-builder':SqlExpressionBuilder
  },
  data () {
    return {
      nameTextboxSize:20,
      showFullTablePanel:false,
      showFullColumnPanel:false,
      tableOverlay:3,
      columnOverlay:2,
      main_filterUI:[],
      secondary_filterUI:{groupList:[]},
      selectTableRule:[],
      selectColumnRule:[],
      stepper:1,
      columnPanelList:[],
      changeText:VALUE_TO_TEXT,
      errorListCount:0,
      colorCode:COLOR_CODE,
      outlineColor:BTN_COLOR,
      currentTableObject:{},
      currentColumnObject:{},
      selectedColumns:[],
      validateProgress:false,
      errorList:{},
      isBetween:false,
      tableheight:'500px',
      distinct:true,
      editable: true,
      isDragging: false,
      delayedDragging: false,
      search: '',
      table_search:'',
      join_dialog:false,
      stepname:this.parentdigramobj.cell_name,
      join_from_table:'',
      join_json_for_link: [],
      join_to_table:'',
      jto_column_list:[],
      new_jto_column_list:[],
      jto_column_description:[],
      jfrom_column_list:[],
      new_jfrom_column_list:[],
      jfrom_column_description:[],
      join_json: [],
      operators:JOIN_OPERATOR_ARRAY_JSON,
      filter_operators:FLITER_JOIN_OPERATOR_ARRAY_JSON,
      join_opeator:'_eq_',
      jtype_list:JOIN_TYPE,
      link_id: '',
      jfrom:'',
      jto:'',
      jfrom_drv_table: "false",
      jto_drv_table: "false",
      jfrom_error:'',
      jto_error:'',
      jtype:'Inner Join',
      tbl_dialog:false,
      dbl_click_tbl_cell_name:'',
      dbl_click_tbl_items:[],
      selection_graph: new dia.Graph({},{ cellNamespace: shapes }),
      columns_for_selection:[],
      port_reset_value:0,
      port_radis : 8,
      // table_column_list:[],
      selected_table:'',
      table_list:[],
      connection_str:"",
      schema:"",
      db_type:"",
      datasource_id:"",
      target_connection_str:"",
      target_schema:"",
      target_datasource_id:"",
      default_table_list:"",
      step_headers: [
      { text: 'Select', value: 'forselection', align: "left", sortable: false},
      // { text: 'Filter', value: 'forfilter', align: "left", sortable: false},
      { text: 'Column name', value: 'column_name', align: "left", sortable: false}
      ],
      aggregate_types:AGGREGATE_FUNCTIONS,
      columns_for_filter: [],
      value_type_list: ['Value', 'Date', 'Column'],      
      column_name_list: [],
      filter_column_tbl_obj: {
        's_parentheses': '',
        'column_name': '',
        'operator': '',
        'v_type1': 'Value',
        'v_type2': 'Value',
        'value1': '',
        'value2':'',
        'e_parentheses': '',
        'is_drv_table': false,
        'ref_row_id':'',
        'ref_step_id':''

      },
      selectFromrule:[
      v => !!v || 'Select from required'
      ],
      selectTorule: [
      v => !!v || 'Select to is required'
      ],
      add_join_button: false,
      join_keys_list: [],
      filter_json:[],
      selection_headers:[
      {
        text: "",
        align: "left",
        sortable: false
      },
      { text: 'Column', value: 'column_name', align: "left", sortable: false},
      { text: 'Table', value: 'table_name', align: "left", sortable: false},
      {text: 'Aggregate', value: 'aggregate', align: "left", sortable: false},
      {text: 'Alias name', value: 'alias_name', align: "left", sortable: false},
      {text: 'Order Type', value: 'order_type', align: "left", sortable: false},
      {text: 'Action', value: 'action', align: "left", sortable: false}
      ],
      dragged_tbl_list:[],
      dragged_tbl_list_with_type:[],
      dateformat_dialog: false,
      env_id: '',
      savedstatus:'edited',
      is_error_occured:false,
      tablelistpanelproperties:'',
      dateformatobj:'',
      auto_increment_id:0,
      selection_error:'',
      paper:'',
      source_cells_detail:[],
      combined_error_list:{},
      join_error_json:{},
      validation_response:{},
      policy_list:[],
      filter_popup_index:0,
      showpolicies:true,
      datasource_error:'',
      env_name:this.$session.get('selected_env').name,
      steps_table_list:[], //this variable is for pass step table list to tablelist panel when user perform server search.
      old_stepname:'',
      stepType:"Selection",
      selectionStepType:'Selection',
      mergeStepType:'Merge',
      minusStepType:'Minus',
      step_type_all:"Distinct",
      sourceCellColumnReferences:[], // this variable used to save the source cells references.Which we will use to add refernce detail in rows while performing selection,merge,minus,
      previous_columns_for_selection:[], //use for the reference updatation
      existingsteplist:[],
      columnDatatableList:{
        headers: [
        { text: 'Column Name', value: 'column_name',width : "30%"},
        { text: 'Description', value: 'description',width : "70%"},
        ], 
        actions: [],
        sorting_type: CLIENT_SIDE,
        filterType: CLIENT_SIDE,
        paginationType: CLIENT_SIDE,
        total_count: 0,
        rows: [],
        select_rows:true,
        hidePagination:false,
        hidePerPageArray:true,
        hideRowInformation:true,
        syncHeaderScroll:false,
        multipleGrid:true,
        hideDownload:true,
        disableDefaltSorting:true,
        envIpJson : {
              "filter": [],
              "sort": {
                "column": "",
                "type": ""
              },
              "page": 1,
              "page_size": "*"
            },
      },
      temp_select_data:[],
      defaultColumnDatatableList:{}, // used to reset the table.
      readOnlyGraph:{},
      vueEditorObj:{},
      descriptionPanel:false,
      pd_description:'',
      selectedElementForContextMenu:{},
      isCellSelected:false,
      old_alias:'', // this variable is used to trace is user perform the change in the alias or not.
      columnDeletionPopUp:false,
      columnDeletionType:'only_from_current_step',
      columnDeletionOptions:[{
        "label":"Only delete from this step",
        "value":'only_from_current_step',
        "Description":"Selection column get deleted from current step only. No direct or cascading references get deleted."
      },{"label":"Delete from direct referenced step",
        "value":'delete_direct_reference',
        "Description":"Selection column get deleted from current step & direct referenced step.It wont delete from cascading references."
      },
      {"label":"Delete from cascading steps",
        "value":'delete_cascading_reference',
        "Description":"All direct and cascading reference columns will get deleted along with current step selection."
      },
      ],
      graphFor:'data_selection',
      selectedElementForContextMenu:"",
      copyJoinJson:[],
      removedColumnIndex:-1,
      stepSeqNo:1,
      showErrorPopUp:true,
      oldFilterObjDetails:{},
      redoUndoFilterObj:{},
      autoValidateTimer:0,
      showExpressionOpt:false,
      expressionDraggedCol:{},
      createReportingTable:false,
      reportingTableName:'',
      availableReportingTables:[],
      datasourceKeys : [
        {"key":'source_data',"display":"Source",'hasBusinessObj':true},
          {"key":'target_data',"display":"Target",'hasBusinessObj':true},
          {"key":'catalog_data',"display":"Catalog"},
          {"key":'control_data',"display":"Control"},
          {"key":'dictionary_data',"display":"Dictionary",'isForFetchAliases':true},
          {"key":'object_librarian_data',"display":"Object Librarian"}
        ],
      dsList:[],
      // reportingTableCreateOnDS:'source_data'
      }
  },
  props:{
    parentdigramobj:{
      type:Object,
      default:{"name":"dataselection"}
    },
    savedatastatus:{
      type:String,
      default:''
    },
    iserroroccured:{
      type:Boolean,
      default:false
    },
    policies:{
      type:Array,
      default:[]
    },
    tableListLoader:{
      type:Boolean,
      default:false
    },
    datasourceerror:{
      type:String,
      default:''
    },
    acceleratorObj:{
      type:Object,
      default:{}
    },
    readonly:{
      type:Boolean,
      default:false
    },
    redoundoCommandObj:{
      type:Object
    },
    redoundoMsg:{
      type:String,
    },
    undoStack:{
      type:Array
    },
    redoStack:{
      type:Array
    },
    environmentDetails:{
        type:Object,
        default:()=>{
            return {}
        }
    },
  },
  watch: {
    '$store.state.errorList':{
      handler(newValue){
        if(!_.isEmpty(newValue)){
          // let idArray = Object.keys(newValue);
          if(this.parentdigramobj && newValue[this.parentdigramobj.stepId]){
            this.validation_response = newValue[this.parentdigramobj.stepId];
          }          
        }
      },
      deep:true
    },
    acceleratorObj(newvalue){

    },
    datasourceerror(newvalue){
      this.datasource_error =newvalue
    },
    policies(newvalue){
      // debugger;
      this.policy_list = newvalue;
    },
    savedatastatus(newvalue,oldvalue){
      this.savedstatus = newvalue
    },
    iserroroccured(newvalue,oldvalue){
      this.is_error_occured =newvalue
    },
    readonly(newvalue){

    },
    parentdigramobj(newvalue,o){
      // debugger;
      // this.errorListCount = 0
      var _this = this
      this.stepper = 1
      var details_for_table_panel = {}
      this.temp_select_data = []
      this.source_cells_detail = []
      this.column_name_list = []
      this.dragged_tbl_list = []
      this.dragged_tbl_list_with_type = []
      this.stepname = newvalue.cell_name
      this.old_stepname = newvalue.cell_name
      this.steps_table_list = []
      this.validation_response ={}
      this.validateProgress=false
      this.stepType = newvalue.steptype
      this.existingsteplist = []
      this.readOnlyGraph = {}
      this.pd_description = ''
      this.vueEditorObj = {'description':''}
      this.columnDeletionType='only_from_current_step'
      this.columns_for_filter = [{
          's_parentheses': '',
          'column_name': '',
          'operator': '',
          'v_type1': 'Value',
          'v_type2': 'Value',
          'value1': '',
          'value2':'',
          'e_parentheses': '',
          'is_drv_table': false,
          'ref_row_id':'',
          'ref_step_id':'',
          'id':this.get_new_id()
          }]
      this.main_filterUI = []
      this.secondary_filterUI = {groupList:[]}
      this.oldFilterObjDetails = {}
      this.redoUndoFilterObj = {}
      // pass join type array properly
      this.jtype_list = JOIN_TYPE
      this.jtype = 'Inner Join'
      this.step_type_all = "Distinct"

      this.createReportingTable = false
      this.reportingTableName = ''
      // this.reportingTableCreateOnDS ='source_data'
      if(!_.isEmpty(_this.environmentDetails))
        _this.manageEnvironmentDetails(_this.environmentDetails)
      this.getAllAvailableReportingTable()
      if(this.stepType === this.mergeStepType){
        this.jtype_list = [this.mergeStepType]
        this.jtype = this.mergeStepType
        this.step_type_all = "Merge All"
      }
      if(this.stepType === this.minusStepType){
        this.jtype_list = [this.minusStepType]
        this.jtype = this.minusStepType
      }

      this.validateStepOnUserAction()
      if(newvalue.existingsteplist)
        this.existingsteplist = newvalue.existingsteplist

      this.stepSeqNo = newvalue.stepSeqNo
      if(newvalue.tables_detail){
        details_for_table_panel.tables_detail = newvalue.tables_detail
        this.table_list = newvalue.tables_detail.table_list
        this.connection_str= newvalue.tables_detail.connection_str
        this.schema= newvalue.tables_detail.schema
        this.db_type= newvalue.tables_detail.db_type
        this.default_table_list = newvalue.tables_detail.default_table_list
        this.datasource_id = newvalue.tables_detail.datasource_id
        this.target_schema = newvalue.tables_detail.target_schema
        this.target_connection_str = newvalue.tables_detail.target_connection_str
        this.target_datasource_id = newvalue.tables_detail.target_datasource_id
        details_for_table_panel.table_list = this.table_list
        details_for_table_panel.connection_str = this.connection_str
        details_for_table_panel.schema = this.schema
        details_for_table_panel.db_type = this.db_type
        details_for_table_panel.default_table_list = this.default_table_list
      }
      //Code to bind error message if user perform validation at flowchart level
      var flowchart_errors = this.$store.state.errorList
      if(flowchart_errors && this.parentdigramobj && flowchart_errors[newvalue.stepId]){
          this.validation_response = flowchart_errors[newvalue.stepId];
        }

      if (newvalue.env_id){
        this.env_id = newvalue.env_id
      }
      if(newvalue.step_data){
         if(newvalue.step_data.description){
          this.pd_description = newvalue.step_data.description
          this.vueEditorObj = {'description':newvalue.step_data.description}
        }

        if(newvalue.step_data.join){
          this.join_json = newvalue.step_data.join
          this.copyJoinJson = _.cloneDeep(this.join_json)
        }
        else{
          this.join_json = [] 
        }
        
        // default distinct should be true Email by Manish Sir - Dec 18,2020
        this.distinct = true
        if(newvalue.step_data.distinct === false)
          this.distinct = false
        
        // if(this.stepType === this.mergeStepType){
        //   this.distinct = true
        //   if(newvalue.step_data.distinct)
        //   this.distinct = false
        // }
        if(newvalue.step_data.selection){
          let columnsForSelection = _.cloneDeep(newvalue.step_data.selection)
          columnsForSelection.forEach((obj)=>{
            obj.columnDeletionPopUp = false
            if(!obj.alias_name)
              obj.alias_name = obj.column_name
          })
          this.previous_columns_for_selection = _.cloneDeep(columnsForSelection)
          this.columns_for_selection = columnsForSelection
          this.callgetColumnsMoreInfo("col_format")
        }
        else{
          this.previous_columns_for_selection = []
          this.columns_for_selection = []
        }
        if(newvalue.step_data.filter){
          this.columns_for_filter = newvalue.step_data.filter
        }
        if(!_.isEmpty(newvalue.step_data.reporting_table_details)){
          this.createReportingTable = true
          this.reportingTableName = newvalue.step_data.reporting_table_details.reportingTableName
          // this.reportingTableCreateOnDS =  newvalue.step_data.reporting_table_details.reportingTableCreateOnDS
        }

        if(newvalue.step_data.main_filterUI){
          this.main_filterUI = newvalue.step_data.main_filterUI
          
          if(newvalue.step_data.secondary_filterUI){
            this.secondary_filterUI = newvalue.step_data.secondary_filterUI
          }

          this.oldFilterObjDetails = {"main_filterUI":_.cloneDeep(this.main_filterUI),
          "secondary_filterUI":_.cloneDeep(this.secondary_filterUI)}
        }

        if(newvalue.step_data.graph){
          let unzippedGraph = graphObj.unzipGraph(this,{"cells":newvalue.step_data.graph.cells,'isAug2020ReleasedUI':newvalue.step_data.isAug2020ReleasedUI},this.graphFor)
          this.selection_graph.fromJSON(JSON.parse(JSON.stringify(unzippedGraph)));
          let links = this.selection_graph.getLinks()
          links.forEach(link=>{
            edcLinktoolObj.manageLinktools(link,this.paper,joint,this)
          })
          // in the case of minus/merge, the migrated pd from old archivist or from the new archivist which has designed before Aug 2020 release, has an issue.When user rename the step , that step data not get changed in the dragged_tbl_list_type.
          let elements = this.getAlreadyDraggedElements()
          for(let i=0;i<elements.length;i++){
            let element = elements[i]
            if((element.prop('is_drv_table') === false||element.prop('is_drv_table') === 'false') && element.prop('table_name') && element.prop('table_name').indexOf(' ')>=0){
              element.prop('is_drv_table',true)
              element.prop('table_type','step')
            }
            this.dragged_tbl_list.push(element.prop('table_name'))
            this.dragged_tbl_list_with_type.push({"table_name":element.prop('table_name'),"table_type":element.prop('table_type')})
          }
        }
        else{
          this.selection_graph.clear()
        }
        /* if(newvalue.step_data.validation_response){
          this.validation_response = newvalue.step_data.validation_response
        } */
        // this.Perform_Validation(this.validation_response)
        if(this.dragged_tbl_list_with_type){
          let tableListWithoutDescription = []
          this.dragged_tbl_list_with_type.forEach(item =>{
            if(item.table_type === 'table')
              tableListWithoutDescription.push(item.table_name)
          })
          if(tableListWithoutDescription.length)
            this.loadTableDescription(tableListWithoutDescription)

          getBulkTablesColumns(this,this.table_column_list,this.env_id,this.connection_str,this.schema,_.cloneDeep(this.dragged_tbl_list_with_type)).then(tableColumnResponse=>{
            for (var i = tableColumnResponse.length - 1; i >= 0; i--) {
              var table_name = tableColumnResponse[i].table_name
              var table_columns = tableColumnResponse[i].table_column_list
              for (var j = 0; j < table_columns.length; j++) {
                var filter_dict = {}
                filter_dict['column_name'] = table_name+'.'+table_columns[j]
                filter_dict[table_name+'.'+table_columns[j]] = false
                // filter_dict['data_type_details'] = _data_dict.data_type_details
                this.column_name_list.push(filter_dict)
              }
            }
            if(this.columns_for_filter.length === 0){
              this.add_new_filter_row()
            }
          })
        }
        else{
          this.dragged_tbl_list_with_type =[]
        }

        if(newvalue.step_data.auto_id)
          this.auto_increment_id = newvalue.step_data.auto_id
        else
          this.auto_increment_id = 0

        // if(newvalue.step_data.validation_response)
        //   this.validation_response = newvalue.step_data.validation_response
      }
      this.sourceCellColumnReferences = []
      if(newvalue.source_cells_detail && newvalue.source_cells_detail.length > 0){
        this.source_cells_detail = newvalue.source_cells_detail
        this.table_list = _.cloneDeep(this.default_table_list)
        var step_list = newvalue.source_cells_detail
        for(var i=0;i<step_list.length;i++){

          // Add into sourceCellReferences
          this.sourceCellColumnReferences = _.concat(this.sourceCellColumnReferences,step_list[i].column_list_references)

          for(var each_table_count=0;each_table_count<this.table_list.length;each_table_count++){
            if (this.table_list[each_table_count].title == step_list[i].source_name){
              this.table_list.splice(each_table_count, 1)
              break;
            }
          }
          this.table_list.unshift({"type":"S","title":step_list[i].source_name,"seqNo":step_list[i].seqNo})
          this.steps_table_list.push({"type":"S","title":step_list[i].source_name,"seqNo":step_list[i].seqNo})
          for(var tbl_col_count=0;tbl_col_count<this.table_column_list.length; tbl_col_count++){
            if (this.table_column_list[tbl_col_count].table_name == step_list[i].source_name){
              this.table_column_list.splice(tbl_col_count, 1)
              break
            }
          }
          // this.table_column_list.push({"table_name":step_list[i].source_name, "table_column_list":step_list[i].columns})
          let key = this.env_id + '_table_column_list';

          if (!this.$store.state.cacheData[key]) {
            this.$store.state.cacheData[key] = [];
          }
              // this.table_column_list.push({"table_name":step_list[i].source_name, "table_column_list":step_list[i].columns})

              var table_name = step_list[i].source_name
              var table_columns = step_list[i].columns
              var cols_description = step_list[i].cols_description
              var column_db_details = step_list[i].column_db_details
              for (var k = this.dragged_tbl_list_with_type.length - 1; k >= 0; k--) {
                if(this.dragged_tbl_list_with_type[k].table_type === 'step' && (this.dragged_tbl_list_with_type[k].table_name === table_name || this.dragged_tbl_list_with_type[k].table_name === table_name.replace(/\s+/g, '').trim())){
                  for (var j = table_columns.length - 1; j >= 0; j--) {
                    var filter_dict = {}
                    filter_dict['column_name'] = table_name+'.'+table_columns[j]
                    filter_dict[table_name+'.'+table_columns[j]] = true
                    // adding reference_details
                    filter_dict['ref_row_id'] = ''
                    filter_dict['ref_step_id'] = ''
                    var ref_obj = _.find(this.sourceCellColumnReferences,["column_name",table_columns[j]])
                    if(ref_obj){
                      filter_dict['ref_row_id'] = ref_obj['row_id']
                      filter_dict['ref_step_id'] = ref_obj['step_id']
                    }

                    this.column_name_list.push(filter_dict)

                  }
                  break;
                }
              }
              let cacheIndex = -1;
              cacheIndex = _.findIndex(this.$store.state.cacheData[key], function(o) { return o.table_name == table_name; });

              if(cacheIndex >= 0){
                this.$store.state.cacheData[key].splice(cacheIndex, 1); 
              }
               
              this.$store.state.cacheData[key].push({"table_name":table_name, "table_column_list":table_columns,'table_column_descriptions':cols_description,"column_db_details":column_db_details})
            }
          }
          else{
            this.table_list = _.cloneDeep(this.default_table_list)
          }

      edcCellsObj.highlightUnHighlightInvalidCell(_this.selection_graph,this.paper,[])

      // as we required step tables also.so need to copy table list to default
      this.default_table_list = _.cloneDeep(this.table_list)
      details_for_table_panel.table_list = this.table_list
      details_for_table_panel.default_table_list = this.default_table_list
      details_for_table_panel.env_id = this.env_id
      this.tablelistpanelproperties = details_for_table_panel


      // check dragged steps are present in the incomming steps or not. If not then show its in the error.
      let tableErrorList =[]
      
      this.dragged_tbl_list_with_type.forEach((obj)=>{
        if(obj.table_type != 'step')
          return
        let incomingObj = _.find(this.source_cells_detail,(sourceObj)=>{
          return sourceObj.source_name === obj.table_name
        })
        if(!incomingObj){
          if(!tableErrorList.indexOf(obj.table_name)>-1)
            tableErrorList.push(obj.table_name)
        }
      })
      if(tableErrorList.length){
        this.validation_response = {}
        this.$store.state.errorList[newvalue.stepId]={}
        let errorMsg = "Table names(s) "+tableErrorList.join(", ")+" does not found in the incomming steps"
        this.validation_response = {"step_data": {"select_table_list":errorMsg}}
        this.$store.state.errorList[newvalue.stepId] = {"step_data": {"select_table_list":errorMsg}}
        let errorCellList = []
        tableErrorList.forEach((obj)=>{
          let elements = _this.selection_graph.getElements().filter(element=>element.prop('table_name')=== obj && element.prop('table_type') === 'step')
          if(elements.length)
            errorCellList.push(elements[0].id)
        })
        edcCellsObj.highlightUnHighlightInvalidCell(_this.selection_graph,this.paper,errorCellList)
      }

      // check every join contain the condition or not. If not remove link from the graph
      for(let i=0;i<this.join_json.length;i++){
        let currentJoin = this.join_json[i]
        let joinID=Object.keys(currentJoin)[0]
        if(!currentJoin || !currentJoin[joinID] || !currentJoin[joinID].condition || !currentJoin[joinID].condition.length){
          this.removeCell(joinID)
        }
      }
    },
    isDragging(newValue) {
      if (newValue) {
        this.delayedDragging = true;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    columns_for_selection:{
      handler(newvalue,oldvalue){
        // here find difference logic will go
        let old_val = _.cloneDeep(this.previous_columns_for_selection)
        this.UpdateReferences(_.cloneDeep(this.previous_columns_for_selection),_.cloneDeep(newvalue))
        this.previous_columns_for_selection = _.cloneDeep(newvalue)
        // this.savedata(false)
        if(old_val.length != newvalue.length){
          // means something has been added or deleted. Now call this.savedata(false) function. Becuase other fields doing that on their change event.
          this.savedata(false)
        }
        this.selectColumnRule=[]
      },
      deep:true,
    },
    join_json:{
      handler(newvalue){
        this.savedata(false)
        if(this.stepType === this.mergeStepType)
          this.performMergeSelection(newvalue)
      },
      deep:true,
    },
    
    columns_for_filter(newvalue){
      this.savedata(false)
    },
    column_name_list(newvalue){
      this.savedata(false)
    },
    dragged_tbl_list_with_type(newvalue){
      this.selectTableRule = []
    },
    distinct(newValue){
      this.savedata(false)
    },
    stepper(newvalue){
      this.selectTableRule = []
      this.selectColumnRule = []
      if(newvalue!="1" && this.dragged_tbl_list_with_type.length === 0)
        this.selectTableRule = [() => false]
      if(newvalue != "2" && this.dragged_tbl_list_with_type.length != 0 && this.columns_for_selection.length === 0)
        this.selectColumnRule = [() => false]
    },
    redoundoCommandObj(newvalue){
      if(!newvalue || !newvalue.action_to_take || newvalue.step_id != this.parentdigramobj.stepId)
        return
      this.manageRedoUndoActions(_.cloneDeep(newvalue))
    },
    redoundoMsg(newvalue){

    },
    stepname(newvalue){
      if(newvalue){
        this.nameTextboxSize=Math.max(20,newvalue.length>60?60:newvalue.length)
      }
    },
    undoStack(newvalue){
      if(newvalue){

      }
    },
    redoStack(newvalue){
      if(newvalue){

      }
    }
  },
  mounted() {
    let _this = this
    _this.defaultColumnDatatableList = _.cloneDeep(_this.columnDatatableList)
      // this.tableheight='530px';
      if(this.$refs.join_panel_ref.$el)
        var join_flex = this.$refs.join_panel_ref.$el
      else
        var join_flex = this.$refs.join_panel_ref

      join_flex.addEventListener('drop',drop_on_join_panel)
      join_flex.addEventListener('dragover',allowDrop_on_join_panel)

      if(this.$refs.col_panel_ref.$el)
        var col_flex = this.$refs.col_panel_ref.$el
      else
        var col_flex = this.$refs.col_panel_ref

      col_flex.addEventListener('drop',drop_on_col_panel)
      col_flex.addEventListener('dragover',allowDrop_on_col_panel)


      function drop_on_col_panel(event){
        event.preventDefault();
        if(_this.stepType === _this.mergeStepType)
          return
        if(!_this.currentColumnObject || !_this.currentColumnObject.column_name)
        return
        _this.performselection(_.cloneDeep(_this.currentColumnObject))
        _this.currentColumnObject = {}
      }

      function drop_on_join_panel(event) {
        event.preventDefault();
        // debugger
        var table_name = _this.currentTableObject.title;
        var table_type = _this.currentTableObject.type;
        var table_description = _this.currentTableObject.table_description

      /* Commented below part on 19th may 2021 as we hae implemented auto replacement of the object */

      // dont allow more than 2 tables for in case of merge and minus step
        // if(_this.stepType === _this.mergeStepType || _this.stepType === _this.minusStepType){
        //   if(_this.dragged_tbl_list_with_type.length === 2){
        //     _this.datasource_error = 'Only two tables are allowed in this step'
        //     return 
        //   }
        // }
      _this.addTableInSelection({
        "positionX":event.offsetX,
        "positionY":event.offsetY,
        "table_name":table_name,
        "table_type":table_type,
        "table_description":table_description
      },true)
      
    }
    function allowDrop_on_join_panel(event) {
      event.preventDefault();
    }

    function allowDrop_on_col_panel(event) {
      event.preventDefault();
    }

    var paper_heigth = 400
    var paper_width = 890
    var currentScale = 1
    var gridSize = edcGraphConstantsObj.GRID_SIZE;
    var gridColor = edcGraphConstantsObj.GRID_COLOR;
    var graph =_this.selection_graph
    var paper = new dia.Paper({
      cellViewNamespace: shapes,
      el: $('#selection_paper'),
      model: graph,
      gridSize: gridSize,
      drawGrid:{ name: 'mesh', args: { color: gridColor }},
      height: paper_heigth,
      width: paper_width,
      restrictTranslate: true,
      defaultLink: edcLinkObj.getDefaultLinkProperies(),
      validateConnection: function(cellViewS, magnetS, cellViewT, magnetT, end, linkView){
        // Prevent linking from output ports to input ports within one element.
        if (cellViewS === cellViewT) 
          return false;
        return true
      },
      validateMagnet: function(cellView, magnet) {
        // Note that this is the default behaviour. Just showing it here for reference.
        // Disable linking interaction for magnets marked as passive (see below `.inPorts circle`).
        return magnet.getAttribute('magnet') !== 'passive';
      },
      interactive: { 
        vertexAdd: false 
      },
      // Enable marking available cells & magnets
      markAvailable: false,
      // Enable link snapping within 75px lookup radius
      snapLinks: { 
        radius: 75 
      },
      linkPinning:false,
    });

    var selected;
    paper.on("link:connect", function(linkView) {
      _this.join_dialog =true
      _this.getLinkDetails(linkView.model)
    }).on("link:pointerdblclick", function(linkView) {
      _this.join_dialog =true
      _this.getLinkDetails(linkView.model)
    }).on('link:mouseenter', function(linkView) {
      linkView.showTools();
    }).on('link:mouseleave', function(linkView) {
          linkView.hideTools();
    }).on("element:mouseover",function(elementView){
      let element = elementView.model
      _this.isCellSelected = true
      _this.selectedElementForContextMenu = element

      // to add the shadow to the all embedded cells
      let embeddedCell = edcAnonymousObj.getEmbeddedCell(element)
      if(!embeddedCell.prop('is_embed'))
        return        
      embeddedCell.attr('border/strokeWidth',20)
      embeddedCell.attr('border/stroke','transparent')
    }).on("element:mouseout",function(elementView){

    }).on('element:mouseleave',function(elementView){
        let element = elementView.model
        element.removeAttr('border/filter')
        // to remove the shadow from the all embedded cells
        let embeddedCell = edcAnonymousObj.getEmbeddedCell(element)
        if(!embeddedCell.prop('is_embed'))
          return
        embeddedCell.attr('border/strokeWidth',2)
        embeddedCell.attr('border/stroke','#000000')
    }).on("element:contextmenu",function(elementView){
        let element = elementView.model
        _this.selectedElementForContextMenu = element
        _this.$refs.ctxMenu.open()
      });

    paper.on('element:mouseover', function(cellView) {
      _this.selected_table = cellView
      selected = cellView
    }).on('element:pointerdblclick',function(cellView){
      var cell = cellView.model
      _this.search = ''
      _this.showTableColumns(cell.prop('table_name'),cell.prop('is_drv_table'), cell.prop('table_type'))
    }).on('blank:mouseover blank:contextmenu link:mouseover', function(cellView) {
      _this.selected_table=''
      _this.isCellSelected = false
      _this.selectedElementForContextMenu = {}
    });

    graph.on('add', function(cell) {
      if(cell.isElement() && cell.prop('is_embed')){
        let availableElementList = _this.selection_graph.findModelsInArea(cell.getBBox()).filter(el => el !== cell && el.prop('is_embed'));
        let moreOperationInfo = {"newObject":{"table_name":cell.prop('table_name'),"is_drv_table":cell.prop('is_drv_table'),"step_id":cell.id,'table_description':cell.prop('table_description'),"table_type":cell.prop('table_type')}}
        if(availableElementList.length){
          moreOperationInfo['oldObject'] = {
            "table_name":availableElementList[0].prop('table_name'),"is_drv_table":availableElementList[0].prop('is_drv_table'),"step_id":availableElementList[0].id,'table_description':availableElementList[0].prop('table_description'),"table_type":availableElementList[0].prop('table_type')
          }
        }

        _this.showTableColumns(cell.prop('table_name'),cell.prop('is_drv_table'), cell.prop('table_type'),true,moreOperationInfo)

      }
      if(cell.get('type') === 'link'){
        edcLinktoolObj.manageLinktools(cell,_this.paper,joint,_this)
      }
    }).on('remove',function(cell,custom,eventSource){
      if (cell.isLink()) {
        _this.perform_link_Removed(cell.id,cell,eventSource)
        // if(_this.readonly)
        //   _this.selection_graph.fromJSON(_this.readOnlyGraph)
      }
      else if(cell.isElement()){
        _this.perform_element_Removed(cell)
      }
    }).on('change',function(cell){
      // _this.savedata(false)
    });

    $('#selection_paper')
    .attr('tabindex', 0)
    // removed mouse over. used 'click' listner. There was one issue with mouse over in case of scrolling criteria. Issue listed by Manish Sir.
    .on('click', function() {
      this.focus();
    })
    .on('keydown', function(e) {
      if(_this.readonly)
        return
      var key = e.which || e.keyCode;
      var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false);
        //delete table list
        if(key === 46 && _this.selected_table){
          _this.selectedElementForContextMenu = _this.selected_table.model
          _this.contextMenuClicked('delete')
        }
      });

    $('#mainflex').on('keydown',function(event){
      if (event.ctrlKey && (event.code === 'KeyZ'||event.code === 'KeyY'))
          event.preventDefault()
    });
    _this.paper = paper
  },
  computed: {
    isMergeStep(){
      return this.stepType === this.mergeStepType
    },
    table_column_list(){
      let data = this.$store.state.cacheData[this.env_id+'_table_column_list'];
      return data ? data : [];
    },
    dragOptions() {
      return {
        animation: 0,
        group: "description"        
      };
    },
    listString() {
      return JSON.stringify(this.list, null, 2);
    },
    columnDeletionDescription(){
      return _.find(this.columnDeletionOptions,["value",this.columnDeletionType]).Description
    },
    undoStackCount(){
      if(!this.parentdigramobj || !this.parentdigramobj.stepId)
        return 0
      return _.filter(this.undoStack,(obj)=>{
        return obj.step_id === this.parentdigramobj.stepId
      }).length
    },
    redoStackCount(){
      if(!this.parentdigramobj || !this.parentdigramobj.stepId)
        return 0
      return _.filter(this.redoStack,(obj)=>{
        return obj.step_id === this.parentdigramobj.stepId
      }).length
    }
  },
  methods:{
    validateStepOnUserAction(){
      // we are going to call validate object after curtain time after action get performed because graph take sometime to update its json.
        var _this = this
        _this.showErrorPopUp = false
        clearInterval(_this.autoValidateTimer)
				_this.autoValidateTimer = setTimeout(()=>{
          _this.showErrorPopUp = true
          _this.validatestepdata()
          _this.$emit('validatePD')
        },edcGraphConstantsObj.AUTO_VALIDATOR_TIMER)
    },
    manageRedoUndoActions(redoundoCommandObj){
      var _this = this
      var graphElements = this.selection_graph.getElements()
      if(redoundoCommandObj.action_to_take === 'step_name_changed_from_inside'){
        if(!redoundoCommandObj.update_details)
          return
        this.old_stepname = this.stepname
        this.stepname = redoundoCommandObj.update_details.new_step_name
      }
      else if(redoundoCommandObj.action_to_take === 'remove_table'){
        this.stepper = 1
        this.removeCell(redoundoCommandObj.element.id)
      }
      else if(redoundoCommandObj.action_to_take === 'add_table'){
        this.stepper = 1
        _this.addTableInSelection(redoundoCommandObj.element,false)
        if(redoundoCommandObj.relatedLinks)
          _this.addLinksInJoin(redoundoCommandObj.relatedLinks,redoundoCommandObj.relatedJoins)
      }
      else if(redoundoCommandObj.action_to_take === 'add_join_link'){
        this.stepper = 1
        _this.addLinksInJoin([redoundoCommandObj.link],[redoundoCommandObj.reletedJoin])
      }
      else if(redoundoCommandObj.action_to_take === 'remove_column'){
        this.stepper = 2
        // here we need to check index of column exist in the data, if yes then remove from that index or remove from last
        let remove_index = this.columns_for_selection.length-1
        if(redoundoCommandObj.removedColumnIndex>-1)
          remove_index = redoundoCommandObj.removedColumnIndex
        this.columns_for_selection.splice(remove_index,1)
      }
      else if(redoundoCommandObj.action_to_take === 'add_column'){
        this.stepper = 2
        // console.log('redoundoCommandObj',redoundoCommandObj)
        redoundoCommandObj.column['columnDeletionPopUp'] = false
        let add_index = this.columns_for_selection.length
        if(redoundoCommandObj.removedColumnIndex>-1)
          add_index = redoundoCommandObj.removedColumnIndex
        this.columns_for_selection.splice(add_index,0,redoundoCommandObj.column)
      } else if(redoundoCommandObj.action_to_take === 'selection_filter_change'){
        this.stepper = 3
        // this.columns_for_filter = redoundoCommandObj.new_value
        this.redoUndoFilterObj = _.cloneDeep(redoundoCommandObj.new_value)
        // this.main_filterUI = redoundoCommandObj.main_filterUI
        // this.secondary_filterUI = redoundoCommandObj.secondary_filterUI
      } else if(redoundoCommandObj.action_to_take === 'table_replaced'){
        redoundoCommandObj['newObject'] = redoundoCommandObj.new_value
        redoundoCommandObj['oldObject'] = redoundoCommandObj.old_value
        let table_name = redoundoCommandObj.newObject.table_name
        let table_type = redoundoCommandObj.newObject.table_type
        let old_table_name = redoundoCommandObj.oldObject.table_name
        let oldtableIndex = this.dragged_tbl_list_with_type.findIndex(obj=>obj.table_name === old_table_name)

        this.$set(this.dragged_tbl_list_with_type,oldtableIndex,{"table_name":table_name,"table_type":table_type})

        oldtableIndex =  this.dragged_tbl_list.indexOf(old_table_name)
        this.$set(this.dragged_tbl_list,oldtableIndex,table_name)

        let is_drv_table = false
        if(table_type === 'step')
          is_drv_table = true
        this.showTableColumns(table_name,is_drv_table,table_type,false,redoundoCommandObj,true)
      }
      this.validateStepOnUserAction()
      this.savedata()
    },
    removeCell(cell_id){
        let cell = this.selection_graph.getCell(cell_id)
        if(cell){
          let parentCell = cell.getParentCell()
          if(parentCell)
            parentCell.remove()
          else
            cell.remove()
        }
    },
    addTableInSelection(element,sendRedoUndo=false){
      var _this = this
      _this.dragged_tbl_list.push(element.table_name)
      _this.dragged_tbl_list_with_type.push({"table_name":element.table_name,"table_type":element.table_type})
      let shapes = edcShapeObj.getEdcTableShapeWithWrapper({
        "positionX":element.positionX,
        "positionY":element.positionY,
        "table_name":element.table_name,
        "table_type":element.table_type,
        "table_description":element.table_description,
        "addDrvProp":true,
        "id":element.id
        })
      _this.selection_graph.addCells(shapes)
      // shapes[0] means actual table object
      if(sendRedoUndo){
        let zippedElement = graphObj.zipElement(shapes[0],_this.graphFor)
        _this.$emit('sendNonGraphEvent','dragged_table',{'element':zippedElement,'step_id':_this.parentdigramobj.stepId})
      }
    },
    addLinksInJoin(links,reletedJoins){
      let _this = this
      let graphElements = _this.selection_graph.getElements()
      for(let i=0;i<links.length;i++){
        let reletedJoin = _.find(reletedJoins,links[i].id)
        if(reletedJoin){
          let unZippedLink = graphObj.unzipLink(links[i],_this.graphFor,graphElements)
          _this.selection_graph.addCells(unZippedLink)
          _this.join_json.push(reletedJoin)
        }
        _this.copyJoinJson = _.cloneDeep(_this.join_json)
      }
    },
    getAlreadyDraggedElements(){
      return _.filter(this.selection_graph.getElements(),(obj)=>{return obj.prop('is_embed')})
    },
    changeStepper(stepper){
      if(stepper === "2"){
        this.columnPanelList = []
        for(var i= 0;i <this.dragged_tbl_list_with_type.length;i++){
          let is_drv_table = false
          let table_name = this.dragged_tbl_list_with_type[i].table_name
          let table_type = this.dragged_tbl_list_with_type[i].table_type
          if(table_type === 'step')
            is_drv_table = true
          this.showTableColumns(table_name,is_drv_table,table_type,true)
        }
      }
    },
    totalCount(count){
      this.errorListCount = count;
    },
    draggedObject(object){
      this.currentTableObject = object;
    },
    columnDraggedObject(object){
      this.currentColumnObject = object;
    },
    setError(errorList){
      let _this = this;
      // _this.Remove_Error_List();
      debugger;
      _this.Perform_Validation(errorList);
    },
    saveCriteria(columns_for_filter,main_filter,secondary_filter){
      // // debugger;
      // let oldFilter = _.cloneDeep(this.columns_for_filter)
      // let oldMain_filterUI = _.cloneDeep(this.main_filterUI)
      // let oldSecondary_filterUI = _.cloneDeep(this.secondary_filterUI)
      this.columns_for_filter = columns_for_filter;
      this.main_filterUI = main_filter
      this.secondary_filterUI=secondary_filter 
      this.savedata();
      // if(oldFilter.length === columns_for_filter.length){        
      //     return
      // }      
    },
    redoUndoFilterUpdate(filterObj){
      filterObj['step_id'] = this.parentdigramobj.stepId
      this.$emit('sendNonGraphEvent','selection_filter_change',_.cloneDeep(filterObj))
    },
    findBetween(){
      let _this = this;
      _this.isBetween = false;
      // debugger;
      _this.columns_for_filter.map(obj=>{
        if(obj.operator == '_bet_' || obj.operator === '_nt_bet_') 
          _this.isBetween = true;
      });
    },
    get_new_id(){
      return ++this.auto_increment_id;
    },
    datePicker(idx){
      this.filter_popup_index = idx
      var date_details = this.columns_for_filter[idx].datedetails
      if(date_details)
        this.dateformatobj = _.cloneDeep(date_details)
      else
        this.dateformatobj ={}
      this.dateformat_dialog = true
    },
    getdate(index,data){
      index = this.filter_popup_index
      var date_data = _.cloneDeep(data)
      this.columns_for_filter[index].value = date_data["value"]
      this.columns_for_filter[index].datedetails = date_data
      this.savedata()
      this.dateformat_dialog = false
    },
    resetIsDrvTable(c_name, idx){
      for(var i=0;i< this.column_name_list.length;i++){
        if(this.column_name_list[i][c_name]){
          this.columns_for_filter[idx].is_drv_table = this.column_name_list[i][c_name]
          break;
        }else{
          this.columns_for_filter[idx].is_drv_table = false
        }
      }
      this.savedata()
    },
    perform_link_Removed(link_id,link,eventSource){
      let deletedJoin = {}
      for(var i=0;i< this.join_json.length;i++){
        if(Object.keys(this.join_json[i])[0]===link_id){
          deletedJoin = _.cloneDeep(this.join_json[i])
          this.join_json.splice(i, 1)
          if(eventSource && eventSource.tool){
            // Link deleted by user.So we need to pass that for undo redo
            let zippedLink = graphObj.zipLink(link,this.graphFor)
            this.$emit('sendNonGraphEvent','join_link_deleted',{"reletedJoin":deletedJoin,"link":zippedLink,'step_id':this.parentdigramobj.stepId}) 
          }
          break;
        }
      }
      this.validateStepOnUserAction()
      return deletedJoin
    },
    perform_element_Removed(element){
      if(!element.prop('is_embed'))
        return

      // var table_to_removed = element.attr('text/text').replace(/\s+/g, '').trim()
      var table_to_removed = element.prop('table_name')
      if(!table_to_removed)
        table_to_removed = element.attr('text/text').replace(/\s+/g, '').trim()
      else
        table_to_removed = table_to_removed.trim()


      for (var k = this.column_name_list.length - 1; k >= 0; k--) {
        var tbl_name = this.column_name_list[k].column_name.split('.')[0]
        if(tbl_name === table_to_removed)
          this.column_name_list.splice(k,1)
      }
      
      for (var i = this.dragged_tbl_list_with_type.length - 1; i >= 0; i--) {
        if(this.dragged_tbl_list_with_type[i].table_name === table_to_removed){
          this.dragged_tbl_list_with_type.splice(i,1)
          break;
        }
      }
      if(this.dragged_tbl_list.includes(table_to_removed)){
        this.dragged_tbl_list.splice(this.dragged_tbl_list.indexOf(table_to_removed), 1);
      }

      return
      /* Meeting Date: 12th May 2021 (Nirav & Ed). Now requirement get changed. 
        If the user delete the table we do nothing because when user will add new table we will check is that table has a column same as the deleted tables . Same thing applied for the filter */ 
      if(!element.prop('is_drv_table')){
        for (var i = this.columns_for_selection.length - 1; i >= 0; i--) {
          if(this.columns_for_selection[i].table_name === table_to_removed)
            this.columns_for_selection.splice(i,1)
          }
        for (var j = this.columns_for_filter.length - 1; j >= 0; j--) {
          var tbl_name =''
          if(!this.columns_for_filter[j].column_name)
            continue
          tbl_name = this.columns_for_filter[j].column_name.split('.')[0]
          if(tbl_name === table_to_removed)
            this.columns_for_filter.splice(j,1)
        }
      }
      this.validateStepOnUserAction()
    },
    onMove({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element;
      const draggedElement = draggedContext.element;
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
        );
    },
    showTableColumns(table_name,is_drv_table, table_type,show_filter=false,moreOperationInfo={},isFromRedoUndo=false){
      // show_filter variable used to load filter columns in case of user dragged the table on graph.In this case we no need to show table for selection popup we just need to fill up the filter combo box. Thats why 'var open_column_selection_popup= !show_filter' this stament used 
      var _this = this
      var open_column_selection_popup = !show_filter

      // now we not going to show this popup in case of stepType='Merge'
      if(this.stepType === this.mergeStepType){
        open_column_selection_popup = false
        var links = this.selection_graph.getLinks()
        if(links.length > 0){
          // this.join_dialog = true
          this.getLinkDetails(links[0])
        }
      }

      // this.tbl_dialog = open_column_selection_popup

      // In case of merge
      this.dbl_click_tbl_cell_name = table_name
      var kwargs = {"table_column_list":this.table_column_list, "env_id":this.env_id,
      "conn_str":this.connection_str,"schema":this.schema, "table_name":table_name, "table_type": table_type,"need_column_db_details":true,"description_needed":true}
      getRevisedColumnsWithDescription(this,kwargs).then(tableColumnResponse => {
        this.dbl_click_tbl_items = []
        // to check response is list or object we cannot use build in function because microsoft 
        // age might not support it. So logic is this if table response contain the 
        // column list key means its an object else its any array 
        console.log("tableColumnResponse",tableColumnResponse)
        var column_response = tableColumnResponse
        var all_col_description = []
        let columnDataTypeList  = tableColumnResponse.column_db_details?tableColumnResponse.column_db_details:[]
        if(tableColumnResponse.column_list){
          column_response = tableColumnResponse.column_list
        }
        var substringIndex = 0
        if(_this.acceleratorObj.acceleration_type === 'jde_ed_e1')
          substringIndex = 2
        if(tableColumnResponse.description)
          all_col_description = tableColumnResponse.description

        for(let i=0;i<column_response.length;i++){
          var _data_dict = {"forselection": false,
          "forfilter":false,
          "table_name": table_name,
          "column_name": column_response[i],
          "is_drv_table":is_drv_table,
          "description":getColDescription(substringIndex,column_response[i],all_col_description,is_drv_table),
          "data_type_details":_.find(columnDataTypeList,["name",column_response[i]])}
          _data_dict['display_text'] = _data_dict['column_name']
          if(_data_dict.description)
            _data_dict['display_text'] = _data_dict['description'] + ' ('+_data_dict['column_name']+')'
          this.dbl_click_tbl_items.push(_data_dict)
          this.columnPanelList.push(_data_dict)

          let filter_dict = {}
          let ref_row_id = ''
          let ref_step_id=''
          if(is_drv_table){
            let ref_obj = _.find(this.sourceCellColumnReferences,["column_name",column_response[i]])
            if(ref_obj){
              ref_row_id = ref_obj['row_id']
              ref_step_id = ref_obj['step_id']
            }
          }
          filter_dict['column_name'] =table_name+'.'+column_response[i]
          filter_dict[column_response[i]+'.'+table_name] = is_drv_table
          filter_dict['ref_row_id'] = ref_row_id
          filter_dict['ref_step_id'] = ref_step_id
          filter_dict['data_type_details'] = _data_dict.data_type_details
          _this.column_name_list.push(filter_dict)
          // add_fliter_row.push(true) ;
        }
        this.columnDatatableList ={}
        this.columnDatatableList = _.cloneDeep(this.defaultColumnDatatableList)
        this.columnDatatableList.rows = _.cloneDeep(this.dbl_click_tbl_items)
    
        /*
          Meeting Date: 12th May 2021 (Nirav & Ed). Now requirement get changed. 
          When user add a new table, we need to check is there any column in the selection or column in the filter has table name which no longer exists in the selected table list.If such column exists , we need to see is new table has that column, if yes then we have to replace table name for respective column (from selection/filter)
        */

        
        if(!_.isEmpty(moreOperationInfo)){
          _this.performAutoReplacement(column_response,moreOperationInfo,false,isFromRedoUndo)
        }

      }).catch({});
      _this.validateStepOnUserAction()
    },
    performAutoReplacement(newTableColumns,moreOperationInfo,isOverrideConfirmation=false,isFromRedoUndo=false){
      /*
        Meeting date:13th May 2021. (Nirav and Ed)
        When user add a new table we have to see is that table overlapping any other table ,if yes then we need to show pop up, Is user want to replace that table with below table, If user say YES then we will replace that table (replace join,column selection and fiters).
        
        If table is not overlapping then check if is there any column from the selection/filter is having table which is not part of the dragged list but same column belong to newly added table then replace all references according to new table.(column selection and fiters.)
        
        Have to update following references
        1)table name 2)is_drv 3)ref_id 4)ref_step_id
      */
      var _this = this
      console.log('moreOperationInfo',moreOperationInfo)
      if(moreOperationInfo['oldObject'] && !isOverrideConfirmation && !isFromRedoUndo){
        // we need confirmation if user want to replace the oldObject wuth newObejct details. If there is no oldObject information then we will direct change the reference.
        _this.showReplacementConfirm(newTableColumns,moreOperationInfo)
        return
      }

      // if isOverrideConfirmation = true means we have to replace all old table from join,selection and the filter with new table. isOverrideConfirmation is false means we have to replace table which table name is not exists in the dragged list and columns are matching.

      if(!moreOperationInfo['oldObject']){
        moreOperationInfo['oldObject'] = {
            "table_name":"","is_drv_table":false,"step_id":""
          }
      }

      // while adding a new table (not replace), call comes here and that cause an issue. When replace call come, old object has table name in a it.If table name not exists , it means user dragged a new table. 
      if(!isFromRedoUndo && moreOperationInfo['oldObject'].table_name){
        this.$emit('sendNonGraphEvent','table_replaced',{"step_id":this.parentdigramobj.stepId,'old_value':moreOperationInfo['oldObject'],'new_value':moreOperationInfo['newObject']})
      }

      let new_table = moreOperationInfo['newObject'].table_name
      let new_drv_table = moreOperationInfo['newObject'].is_drv_table
      let newTableId = moreOperationInfo['newObject'].step_id
      let new_table_description = moreOperationInfo['newObject'].table_description
      let new_table_type = moreOperationInfo['newObject'].table_type

      let old_table = moreOperationInfo['oldObject'].table_name
      let old_drv_table = moreOperationInfo['oldObject'].is_drv_table
      let oldTableId = moreOperationInfo['oldObject'].step_id
      let old_table_description = moreOperationInfo['oldObject'].table_description
      let old_table_type = moreOperationInfo['oldObject'].table_type

      _this.selectionReplacement(new_table,new_drv_table,newTableId,old_table,old_drv_table,newTableColumns)

      if(_this.columns_for_filter.length){
        if(_this.main_filterUI && _this.main_filterUI.length)
          _this.filtersReplacement(_this.main_filterUI,new_table,new_drv_table,newTableId,old_table,old_drv_table,newTableColumns)
        if(_this.secondary_filterUI && _this.secondary_filterUI.groupList && _this.secondary_filterUI.groupList.length){
          for(let i=0;i<_this.secondary_filterUI.groupList.length;i++){
            _this.filtersReplacement(_this.secondary_filterUI.groupList[i].new_columns_for_filter,new_table,new_drv_table,newTableId,old_table,old_drv_table,newTableColumns)
            if(_this.secondary_filterUI.groupList[i].childs && _this.secondary_filterUI.groupList[i].childs.length){
              for(let j=0;j<_this.secondary_filterUI.groupList[i].childs.length;j++)
                _this.filtersReplacement(_this.secondary_filterUI.groupList[i].childs[j].new_columns_for_filter,new_table,new_drv_table,newTableId,old_table,old_drv_table,newTableColumns)
            }
          }
        }
      }
      if(oldTableId){
        /*
          We are going to delete new element and keep old element.
          here we are replacing old element details by new element details , so we no need to worry about join as well reference id.
        */
        let elementForDelete = _this.selection_graph.getElements().filter(el=>el.id === newTableId)
        let elementForReplace = _this.selection_graph.getElements().filter(el=>el.id === oldTableId)[0]

        /*Replace join*/
        _this.joinReplacement(oldTableId,new_table,new_drv_table)
        
        if(elementForDelete && !isFromRedoUndo){
          elementForDelete = elementForDelete[0]
          let replaceElementCopy = elementForReplace.clone()
          elementForReplace.prop('table_name',elementForDelete.prop('table_name'))
          elementForReplace.prop('table_type',elementForDelete.prop('table_type'))
          elementForReplace.prop('is_drv_table',elementForDelete.prop('is_drv_table'))
          elementForReplace.prop('table_description',elementForDelete.prop('table_description'))
          elementForReplace.position(elementForDelete.position().x,elementForDelete.position().y)
          let fontsize = edcAnonymousObj.getSuitableFontSize(elementForDelete.prop('table_name'))
          elementForReplace.attr('label/text',  edcAnonymousObj.getWrappedText(elementForDelete.prop('table_name'),60,joint,2,fontsize))
          elementForReplace.attr('label/font-size',fontsize)
          let parentForReplace = elementForReplace.getParentCell()
          let parentForDelete = elementForDelete.getParentCell()
          parentForReplace.prop('table_name',elementForDelete.prop('table_name'))
          parentForReplace.prop('table_type',elementForDelete.prop('table_type'))
          parentForReplace.prop('table_description',elementForDelete.prop('table_description'))
          parentForReplace.attr('label/text',edcAnonymousObj.getWrappedText(elementForDelete.prop('table_description'),edcConstantObj.BUSINESS_OBJECT_TABLE_WIDTH,joint,2))
          parentForReplace.position(parentForDelete.position().x,parentForDelete.position().y)

          /*performElementRemove use following props to remove related data*/
          elementForDelete.prop('table_name',replaceElementCopy.prop('table_name'))
          elementForDelete.prop('table_type',replaceElementCopy.prop('table_type'))
          elementForDelete.prop('is_drv_table',replaceElementCopy.prop('is_drv_table'))

          if(parentForDelete)
            this.selection_graph.getCell(parentForDelete.id).remove()
          else
            this.selection_graph.getCell(elementForDelete.id).remove()
        }else{
          if(elementForDelete.length)
            var elementForRedoUndo = elementForDelete[0]
          else
            var elementForRedoUndo = elementForReplace
          elementForRedoUndo.prop('table_name',new_table)
          elementForRedoUndo.prop('table_type',new_table_type)
          elementForRedoUndo.prop('is_drv_table',new_drv_table)
          elementForRedoUndo.prop('table_description',new_table_description)
          let fontsize = edcAnonymousObj.getSuitableFontSize(new_table)
          elementForRedoUndo.attr('label/text',  edcAnonymousObj.getWrappedText(new_table,60,joint,2,fontsize))
          elementForRedoUndo.attr('label/font-size',fontsize)
          let parentForRedoUndo = elementForRedoUndo.getParentCell()
          parentForRedoUndo.prop('table_name',elementForRedoUndo.prop('table_name'))
          parentForRedoUndo.prop('table_type',elementForRedoUndo.prop('table_type'))
          parentForRedoUndo.prop('table_description',elementForRedoUndo.prop('table_description'))
          parentForRedoUndo.attr('label/text',edcAnonymousObj.getWrappedText(new_table_description,edcConstantObj.BUSINESS_OBJECT_TABLE_WIDTH,joint,2))
        }
      }
    },
    showReplacementConfirm(newTableColumns,moreOperationInfo){
      let confirmFn = function () {
      this.$parent.performAutoReplacement(newTableColumns,moreOperationInfo,true)
      }
      let new_table = moreOperationInfo['newObject'].table_name
      let old_table = moreOperationInfo['oldObject'].table_name
      let obj = {
        title: 'Replace Table',
        message: 'Do you want to replace table '+old_table+' with table '+new_table,
        type: 'info',
        useConfirmBtn: true,
        onConfirm: confirmFn,
        customCloseBtnText:'Cancel',
        customConfirmBtnText:'Replace'
      }
      this.$refs.simplert.openSimplert(obj)
    },
    selectionReplacement(new_table,new_drv_table,new_step_id,old_table,old_drv_table,newTableColumns){
      let _this = this
      for(let i=0;i<_this.columns_for_selection.length;i++){
        let currentSelection = _this.columns_for_selection[i]
        let columnTableName = currentSelection['table_name']
        let tableObj = _.find(_this.dragged_tbl_list_with_type,["table_name",columnTableName])
        /*
          IF condition explaination
          (!tableObj && !old_table) -> means we have to replace table, no override take place
          (tableObj && columnTableName === old_table) -> means we have replace by considering overriding.
        */
        if((!tableObj && !old_table) || (tableObj && columnTableName === old_table)){
          // now check is that table has a column with same name
          let column_name = currentSelection['column_name']
          if(newTableColumns.indexOf(column_name)<0)
            continue
          currentSelection['table_name'] = new_table
          currentSelection['is_drv_table'] = new_drv_table
          // currentSelection['ref_step_id'] = new_step_id
        }
      }
    },
    filtersReplacement(filters,new_table,new_drv_table,new_step_id,old_table,old_drv_table,newTableColumns){
      let _this = this
      for(let i=0;i<filters.length;i++){
        let currentFilter = filters[i]
        if(!currentFilter['column_name'])
          continue
        let currentTableName = currentFilter['column_name'].split('.')[0]
        let currentColumnName = currentFilter['column_name'].split('.')[1]
        let tableObj = _.find(_this.dragged_tbl_list_with_type,["table_name",currentTableName])
        /*
          IF condition explaination
          (!tableObj && !old_table) -> means we have to replace table, no override take place
          (tableObj && columnTableName === old_table) -> means we have replace by considering overriding.
        */
        if((!tableObj && !old_table) || (tableObj && currentTableName === old_table)){
            if(newTableColumns.indexOf(currentColumnName)<0)
              continue
            currentFilter['column_name'] = new_table+'.'+currentColumnName
            currentFilter['is_drv_table'] = new_drv_table
        }
      }
    },
    joinReplacement(oldTableId,new_table,new_drv_table){
      let _this = this
      let linksWhichBoundToElement = _this.selection_graph.getLinks().filter((lk)=>lk.attributes.source.id===oldTableId||lk.attributes.target.id===oldTableId)
      for(let i=0;i<linksWhichBoundToElement.length;i++){
        let tableKey='jfrom'
        let tableDrvKey = 'jfrom_drv_table'
        if(linksWhichBoundToElement[i].target().id === oldTableId){
          tableKey='jto'
          tableDrvKey = 'jto_drv_table'
        }
        var linkId= linksWhichBoundToElement[0].id
        let join = _.find(_this.join_json,(obj)=>{
          return Object.keys(obj) && Object.keys(obj)[0] === linkId
        })
        if(!join || _.isEmpty(join)|| !join[linkId].condition)
          continue

        join[linkId][tableKey] = new_table
        join[linkId][tableDrvKey] = new_drv_table
        for(let j=0;j<join[linkId].condition.length;j++){
          join[linkId].condition[j][tableKey] = new_table
        }
      }
    },
    performselection(draggedObj){
      // var add_fliter_row = []
      if(this.showExpressionOpt){
        this.expressionDraggedCol = draggedObj
        return
      }
      this.dbl_click_tbl_items = _.cloneDeep(_.uniqWith(this.columnPanelList,_.isEqual))
      this.temp_select_data = [draggedObj]
      for(var i=0;i<this.dbl_click_tbl_items.length;i++){

        var ref_row_id = ''
        var ref_step_id = ''

        if(this.dbl_click_tbl_items[i].is_drv_table){
            // this column belong to step, so we need to add refernces

            // first check column name in references with current column name
            var ref_obj = _.find(this.sourceCellColumnReferences,["column_name",this.dbl_click_tbl_items[i].column_name])
            if(ref_obj){
              ref_row_id = ref_obj['row_id']
              ref_step_id = ref_obj['step_id']
            }
          }
          var is_col_selected = false
          for(var k=0;k< this.temp_select_data.length;k++){
            let current_selected_col = this.temp_select_data[k]
            if(this.dbl_click_tbl_items[i].column_name  === current_selected_col.column_name &&
              this.dbl_click_tbl_items[i].table_name === current_selected_col.table_name)
            {
              is_col_selected = true
              break
            }
          }
        // if(this.dbl_click_tbl_items[i].forselection === true){
          if(is_col_selected){
          this.selection_error = ''
          var row_id =this.get_new_id()
          var alias_name = this.dbl_click_tbl_items[i].column_name
          for(var j=0;j<this.columns_for_selection.length;j++){
            if(this.dbl_click_tbl_items[i].column_name == this.columns_for_selection[j].column_name){
              alias_name = this.columns_for_selection[j].column_name+'_'+row_id;
              break;
            }
          }
          var report_alias = alias_name
          if(this.dbl_click_tbl_items[i].description && this.dbl_click_tbl_items[i].description!=DEFAULT_DESCRIPTION)
            report_alias = this.dbl_click_tbl_items[i].description
          var data_to_push = {'id':row_id,'column_name': this.dbl_click_tbl_items[i].column_name, 'table_name': this.dbl_click_tbl_items[i].table_name, 'alias_name': alias_name,'is_drv_table':this.dbl_click_tbl_items[i].is_drv_table,'error_list':'','ref_row_id':'','ref_step_id':'',"report_alias_name":report_alias,"columnDeletionPopUp":false,"data_type_details":this.dbl_click_tbl_items[i].data_type_details}

          data_to_push['ref_row_id']  =  ref_row_id
          data_to_push['ref_step_id'] =  ref_step_id

          this.columns_for_selection.push(data_to_push)
          this.$emit('sendNonGraphEvent','add_column',{"step_id":this.parentdigramobj.stepId,"column":data_to_push})
         }
        

        var filter_dict = {}
        filter_dict['column_name'] = this.dbl_click_tbl_items[i].table_name+'.'+this.dbl_click_tbl_items[i].column_name
        filter_dict[this.dbl_click_tbl_items[i].table_name+'.'+this.dbl_click_tbl_items[i].column_name] = this.dbl_click_tbl_items[i].is_drv_table
        filter_dict['ref_row_id'] = ref_row_id
        filter_dict['ref_step_id'] = ref_step_id
        this.column_name_list.push(filter_dict)
      }
      this.temp_select_data = []
      if(this.columns_for_filter.length === 0){
        this.add_new_filter_row()
      }
      if(this.columns_for_selection.length > 0)
      this.callgetColumnsMoreInfo("col_format")
      this.validateStepOnUserAction()
      // this.tbl_dialog =false
    },
    delete_selection_row(index){
      if(this.readonly)
        return
      this.removedColumnIndex = index
      this.columns_for_selection.splice(index,1)
      this.validateStepOnUserAction()
      this.savedata()
    },
    add_new_filter_row(){
      var current_filter_length = this.columns_for_filter.length
      if(current_filter_length > 0){
        if(!this.columns_for_filter[current_filter_length-1]['operation'])
          this.columns_for_filter[current_filter_length-1]['operation'] = false   
      }
      var obj = _.cloneDeep(this.filter_column_tbl_obj);
      obj['id'] = this.get_new_id()
      obj['error_list'] = ''
      this.columns_for_filter.push(obj);
    },
    resetValue(idx){
      // alert(this.columns_for_filter[idx].value)
      // this.columns_for_filter[idx].value = '';
      this.savedata()
    },
    delete_filter_row(idx){
      var current_filter_length = this.columns_for_filter.length
      if(current_filter_length > 1 && current_filter_length-1 ===  idx)
        delete this.columns_for_filter[idx-1]['operation']
      this.columns_for_filter.splice(idx, 1)
      if(this.columns_for_filter.length === 0){
        var obj = _.cloneDeep(this.filter_column_tbl_obj);
        this.columns_for_filter.push(obj);
      }
    },
    getLinkDetails(link){
      var _this = this
      this.link_id=link.id
      this.jfrom=''
      this.jto=''
      this.jto_error=''
      this.jfrom_error=''
      this.jfrom_drv_table= "false"
      this.jto_drv_table="false"
      this.join_json_for_link=''
      this.join_keys_list=[]
      this.join_opeator="_eq_"
      // this.jtype = 'Inner Join'
      var source_id = link.source().id
      var target_id = link.target().id
      this.join_from_table = this.selection_graph.getCell(source_id).prop('table_name')
      this.jfrom_drv_table = this.selection_graph.getCell(source_id).prop('is_drv_table')
      var jfrom_table_type = this.selection_graph.getCell(source_id).prop('table_type')
      this.join_to_table = this.selection_graph.getCell(target_id).prop('table_name')
      this.jto_drv_table = this.selection_graph.getCell(target_id).prop('is_drv_table')
      var jto_table_type = this.selection_graph.getCell(target_id).prop('table_type')


      var kwargs = {"table_column_list":_this.table_column_list, "env_id":_this.env_id,"connection_str":_this.connection_str,"schema":_this.schema, "table_name":_this.join_from_table, "table_type": jfrom_table_type,"need_column_db_details":true,"description_needed":true}


      getRevisedColumnsWithDescription(_this,kwargs).then(tableColumnResponse=> {
        this.jfrom_column_list = tableColumnResponse;
        if(tableColumnResponse.column_list)
          this.jfrom_column_list = tableColumnResponse.column_list

        let columnDataTypeList = tableColumnResponse.column_db_details?tableColumnResponse.column_db_details:[]
        var description = tableColumnResponse.description
        this.new_jfrom_column_list = []
        this.jfrom_column_description = description
        _this.bindExtraColumnInfo(this.jfrom_column_list, this.new_jfrom_column_list, description,this.jfrom_drv_table,columnDataTypeList)
      }).catch();


      var kwargs = {"table_column_list":_this.table_column_list, "env_id":_this.env_id,"connection_str":_this.connection_str,"schema":_this.schema, "table_name":_this.join_to_table, "table_type": jto_table_type,"need_column_db_details":true,"description_needed":true}


      getRevisedColumnsWithDescription(_this,kwargs).then(tableColumnResponse=> {
        this.jto_column_list = tableColumnResponse
        if(tableColumnResponse.column_list)
          this.jto_column_list = tableColumnResponse.column_list

        let columnDataTypeList = tableColumnResponse.column_db_details?tableColumnResponse.column_db_details:[]
            var description = tableColumnResponse.description
        this.new_jto_column_list = []
        this.jto_column_description = description
        _this.bindExtraColumnInfo(this.jto_column_list, this.new_jto_column_list, description,this.jto_drv_table,columnDataTypeList)
      }).catch();

      for (var i = 0; i < this.join_json.length; i++) {
        if(this.join_json[i][this.link_id]){
          this.jtype = this.join_json[i][this.link_id].type
          this.join_keys_list = this.join_json[i][this.link_id].condition
          this.join_json_for_link = this.join_json[i][this.link_id].condition
          if(!this.join_json_for_link || !this.join_json_for_link[0] 
          || !this.join_json_for_link[0].error_list)
            continue
          //if table level error occured, then they definitly present in 
          //first condition against table names
          this.jfrom_error = this.join_json_for_link[0].error_list.jfrom
          this.jto_error = this.join_json_for_link[0].error_list.jto
          break;
        }
      }
    },
    bindExtraColumnInfo(source_list,target_list, description,is_drv_table,column_db_details){
      var substringIndex = 0
          if(this.acceleratorObj.acceleration_type === 'jde_ed_e1')
              substringIndex = 2
      _.forEach(source_list,function(each_item){
        let description_text = ""
        if(description && description.length > 0)
        {
          if(is_drv_table === true || is_drv_table === "true")
            substringIndex = 0

          description_text = " ("+getColDescription(substringIndex,each_item,description,false)+")"
        }
        target_list.push({
          "column_name":each_item,
          "description":each_item+""+description_text,
          "data_type_details":_.find(column_db_details,["name",each_item])
        })
      })
    },
    getDescriptionInCondition(column_name,join_side){
          var substringIndex = 0
            if(this.acceleratorObj.acceleration_type === 'jde_ed_e1')
                substringIndex = 2  
              var description = this.jto_column_description
              if(join_side === 'from'){
                description = this.jfrom_column_description
                if(!description)
                  description = []
                if(this.jfrom_drv_table === "true" || this.jfrom_drv_table === true){
                  substringIndex = 0
                }
              }
              else{

                if(!description)
                  description = []

                if(this.jto_drv_table === "true" || this.jto_drv_table === true)
                  substringIndex = 0
              }


              var description_text = getColDescription(substringIndex,column_name,description,false)
              if(description_text)
                return column_name +" ("+description_text+")"
              return column_name
        },

    add_join(){
      if(this.readonly)
        return
      var data = {'jfrom':this.join_from_table,'jto':this.join_to_table,'type':this.jtype, 'condition': this.join_keys_list,'jfrom_drv_table':this.jfrom_drv_table,'jto_drv_table':this.jto_drv_table}

      var jfrom_step_ref_id = ''
      var jto_step_ref_id = ''

      // in few time true or "true" comes as a value. So checking both the values
      if(this.jfrom_drv_table === true || this.jfrom_drv_table === 'true'){
        var from_ref_tab_name = _.find(this.sourceCellColumnReferences,["step_name",this.join_from_table])
        if(from_ref_tab_name){
          jfrom_step_ref_id = from_ref_tab_name.step_id
        }
      }
      
      if(this.jto_drv_table === true || this.jto_drv_table === 'true'){
        var to_ref_tab_name = _.find(this.sourceCellColumnReferences,["step_name",this.join_to_table])
        if(to_ref_tab_name){
          jto_step_ref_id = to_ref_tab_name.step_id
        }
      }

      // Now go for column references.
      var jfrom_col_ref_id = ''
      var jto_col_ref_id = ''
      if(jfrom_step_ref_id){
        var jfrom_col_obj = _.find(this.sourceCellColumnReferences,["column_name",this.jfrom])
        if(jfrom_col_obj)
          jfrom_col_ref_id = jfrom_col_obj.row_id
      }

      if(jto_step_ref_id){
        var jto_col_obj = _.find(this.sourceCellColumnReferences,["column_name",this.to])
        if(jto_col_obj)
          jto_col_ref_id = jto_col_obj.row_id
      }

      data['jfrom_step_ref_id'] = jfrom_step_ref_id
      data['jto_step_ref_id'] = jto_step_ref_id
        
      // if user only want to update join type
      var link_found = false
      for (var i = 0; i < this.join_json.length; i++) {
        if(this.join_json[i][this.link_id]){
          link_found = true
          this.join_json[i][this.link_id].type = this.jtype
          break;
        }
      }


      if(this.jfrom && this.jto){
        if(!link_found){
          var generate_dict = {}
          generate_dict[this.link_id] =data
          this.join_json.push(generate_dict)
        }
        var jfrom_datatype = this.getDataTypeInfo(this.jfrom,this.new_jfrom_column_list).data_type
        var jto_datatype = this.getDataTypeInfo(this.jto,this.new_jto_column_list).data_type
        let _this = this
        if(this.update_join_button){
          var start_index = (this.update_join_button-1);
          var number_of_elements_to_remove = 1;
          var l_id = this.link_id
          var j_from = this.jfrom
          var j_operator = this.join_opeator
          var j_to = this.jto
          var join_from_table = this.join_from_table
          var join_to_table = this.join_to_table
          var _obj = this.join_json.map(function(el) {
            if(el[l_id]){
              el[l_id]['condition'].splice(
                  start_index, number_of_elements_to_remove, {'from_column': j_from,'operator': j_operator, 'to_column': j_to,
                  'jfrom':join_from_table,'jto':join_to_table,"error_list":{},"id":_this.get_new_id(),'jfrom_col_ref_id':jfrom_col_ref_id,'jto_col_ref_id':jto_col_ref_id,'jfrom_datatype':jfrom_datatype,"jto_datatype":jto_datatype}
                )
              }
            });
        }else{
          for (var i = 0; i < this.join_json.length; i++) {
            if(this.join_json[i][this.link_id] && !(this.join_json[i][this.link_id]['condition'].some((elem) => this.jfrom === elem.from_column && this.jto === elem.to_column && this.join_opeator === elem.operator )))
            {
              this.join_json[i][this.link_id]['condition'].push({'id':this.get_new_id(),'from_column': this.jfrom, 'operator': this.join_opeator, 'to_column': this.jto, 'jfrom':this.join_from_table,'jto':this.join_to_table,'error_list':{},'jfrom_col_ref_id':jfrom_col_ref_id,'jto_col_ref_id':jto_col_ref_id,'jfrom_datatype':jfrom_datatype,"jto_datatype":jto_datatype})
              this.join_json_for_link = this.join_json[i][this.link_id].condition
              break;
            }
          }
        }
        this.jfrom=''
        this.jto=''
        this.join_opeator="_eq_"
        this.update_join_button=false
      }
      this.copyJoinJson = _.cloneDeep(this.join_json)
    },
    getDataTypeInfo(column_name,dataTypeInfoList){
      // in future we may require more info about column like datasize,index in table etc
      let returnObj = {"data_type":""}
      if(!dataTypeInfoList || !dataTypeInfoList.length)
        return returnObj
      let dataTypeInfo = _.find(dataTypeInfoList,['column_name',column_name])
      if(dataTypeInfo && dataTypeInfo.data_type_details)
        return dataTypeInfo.data_type_details
      return returnObj
    },
    update_join(j_from, j_cond, j_to, index){
      this.jfrom=j_from
      this.jto=j_to
      this.join_opeator=j_cond,
      this.update_join_button = (index+1)
    },
    delete_join(index){
      if(this.readonly)
        return
      for (var i = 0; i < this.join_json.length; i++) {
        if(this.join_json[i][this.link_id]){
          this.join_json[i][this.link_id]['condition'].splice(index, 1)
          if(this.join_json[i][this.link_id]['condition'].length === 0){
            //delete join key too
            this.join_json.splice(i,1)
          }
          break;
        }
      }
    },
    savedata(is_close){
      let _this = this
      this.savedstatus = "edited"
      this.datasource_error = ''
      if(!_this.stepname)
        return
      var data = this.getdata()
      this.Remove_From_Normal_List(data.step_data.selection)
      this.Remove_From_Normal_List(data.step_data.filter)
      this.Remove_From_Join_List(data.step_data.join)
      // console.log("data",data)

      _this.$emit('update-object',data,is_close)
    },
    getdata(){
      this.env_name = this.$session.get('selected_env').name
      let _this = this
      let graphData = graphObj.zipEdcGraph(_.cloneDeep(this.selection_graph),this.graphFor)

      // code added by siddhesh on 27th May 2024, Got issue like there are links without source and target but having JOIN.
		  // We need to save only those joins which links available in the graph
      let validLinks = _.map(_.filter(graphData.cells, (obj)=>{
        return obj.is_zipped_link === true
      }),"id")

      let validJoins = []
      if(validLinks.length != this.join_json.length){
        for(let i=0;i<this.join_json.length;i++){
          let key = _.keys(this.join_json[i])
          if(key.length && validLinks.indexOf(key[0]) > -1)
            validJoins.push(_.cloneDeep(this.join_json[i]))
        }
      }
      else{
        validJoins = _.cloneDeep(this.join_json)
      }
      

      this.is_error_occured =false
      var filter_data = _.cloneDeep(this.columns_for_filter)
      if(filter_data.length === 1 && (!filter_data[0].column_name && !filter_data[0].operator))
        filter_data = []
      let reporting_table_details = {}
      if(this.createReportingTable){
        // reporting_table_details['reportingTableCreateOnDS'] = this.reportingTableCreateOnDS
        reporting_table_details['reportingTableName'] = this.reportingTableName
      }
      var data = {'stepname':_this.stepname.trim(),
      'step_data':
        {'graph':graphData,'join':_.cloneDeep(validJoins),
        'selection':_.cloneDeep(this.columns_for_selection),'filter':filter_data, 
        'main_filterUI':this.main_filterUI,'secondary_filterUI':this.secondary_filterUI,
        'filter_column_list':['abc'], 'select_table_list':_.cloneDeep(this.dragged_tbl_list),
        'new_select_table_list':_.cloneDeep(this.dragged_tbl_list_with_type),
        'auto_id':this.auto_increment_id,'validation_response':this.validation_response,
        'distinct':this.distinct, 'is_revised':true,'isAug2020ReleasedUI':true,
        'description':this.pd_description,'reporting_table_details':reporting_table_details}
    }
      // if(this.stepType === this.mergeStepType)
      //   data['distinct'] = !this.distinct
      data['step_type'] =this.stepType
    return data
  },
  validatestepdata(){
    var _this = this
    if(this.readonly)
        return
    if(_this.validateProgress){
      return false
    }
    _this.validateProgress=true;
    var data = this.getdata()
    data['previous_steps_data'] = this.source_cells_detail
    data['env_id'] = this.env_id
    _this.selection_error = ''
    _this.combined_error_list = {}
    _this.validation_response={}
    _this.Remove_Error_List();
    validate_process_def_json(this, data).then(validation_response => {
      _this.validateProgress=false;
      var error_data = validation_response ;
      _this.validation_response = validation_response
      _this.$store.state.errorList[this.parentdigramobj.stepId] = validation_response
        //first clear all errors list
        // _this.Perform_Validation(_this.validation_response)
      }).catch(error=>{
         _this.validateProgress = false;
       })
  },
  Perform_Validation(error_data){
    var _this = this
    _this.Remove_Error_List();
    if(this.dragged_tbl_list.length === "0"){
      _this.combined_error_list.Required = []
      _this.combined_error_list.Required.push('Drag atleast one table on the graph')
    }
    if (typeof error_data === 'object'){
      if(error_data.step_data){
        var step_data_errors = error_data.step_data
        if(step_data_errors.selection){
          if(_this.columns_for_selection.length ===0){
            _this.selection_error = 'Atleast one column for selection required'
            if(!_this.combined_error_list.Required)
              _this.combined_error_list.Required = []
            _this.combined_error_list.Required.push(_this.selection_error)
          }
          else{
            _this.Perform_Validation_On_Selection(step_data_errors.selection)
          }
        }
        if(step_data_errors.filter){
          _this.Perform_Validation_On_Filter(step_data_errors.filter)
        }
        if(step_data_errors.graph){
          validate_graph_data_for_selection(_this.selection_graph,_this.paper,step_data_errors.graph.orphan_steps,step_data_errors.graph.links_without_join,GRAPH_TABLE_ERROR_MESSAGES.orphan)
        }
        if(step_data_errors.join){
          _this.Perform_Validation_On_Join(step_data_errors.join)
        }
        var orphas_list = []
        if(step_data_errors.table_list){
          orphas_list = get_elements_ids_by_name(_this.selection_graph,step_data_errors.table_list)
        }
        if(step_data_errors.link){
          validate_graph_data_for_selection(_this.selection_graph,_this.paper,orphas_list,step_data_errors.link,GRAPH_TABLE_ERROR_MESSAGES.dependencynotfound)
        }
      }
    }
  },
  Perform_Validation_On_Selection(selection_error_data){
    var _this = this;
    var rows = Object.keys(selection_error_data)
    rows.map(row=>{ // it will provide rowindex like 0,1,2
      let columnArray = Object.keys(selection_error_data[row]); 
      columnArray.map(column=>{ // it will provide key in that object like alies_name, column_name
        _this.columns_for_selection[row].error_list[column] = selection_error_data[row][column]; 
      })
    })
  },
  Perform_Validation_On_Filter(filter_error_data){
   var _this = this;
    var rows = Object.keys(filter_error_data)
    rows.map(row=>{ // it will provide rowindex like 0,1,2
      let columnArray = Object.keys(filter_error_data[row]); 
      columnArray.map(column=>{ // it will provide key in that object like alies_name, column_name
        _this.columns_for_filter[row].error_list[column] = filter_error_data[row][column]; 
      })
    })
  },
  Perform_Validation_On_Join(join_error_data){
    var _this = this
    for(var i=0;i<_this.join_json.length;i++){
      var join_link_id = Object.keys(_this.join_json[i])[0]
      if(join_error_data[join_link_id]){
        var error_link_json = join_error_data[join_link_id]
        var keys = Object.keys(error_link_json)
        for(var j=0;j<keys.length;j++){
          var join_condition = _this.join_json[i][join_link_id].condition
          for(var k=0;k<join_condition.length;k++){
            if(keys[j]==join_condition[k].id){
              join_condition[k].error_list= error_link_json[keys[j]]
              break;
            }
          }
          _this.join_json[i][join_link_id].condition = join_condition
        }
      }
    }
  },
  Remove_Error_List(){
    validate_graph_data_for_selection(this.selection_graph,this.paper,[],[],'')
    this.Remove_From_Normal_List(this.columns_for_selection)
    this.Remove_From_Normal_List(this.columns_for_filter)
    this.Remove_From_Join_List(this.join_json)
    
  },
  Remove_From_Normal_List(list){
    for(var i=0;i<list.length;i++){
      list[i].error_list = {}
    }
  },
  Remove_From_Join_List(list){
    for(var i=0;i<list.length;i++){
      var join_link_id = Object.keys(list[i])[0]
      for(var j=0;j<list[i][join_link_id].condition.length;j++){
        list[i][join_link_id].condition[j].error_list={}
      }
    }
  },
  Get_Json_As_String(ind,json_data,add_to){ //index, error_msg, selection
    for(var key in json_data){
      if(add_to === 'selection')
        this.combined_error_list.Selection.push('Row no. '+(ind+1)+':'+json_data[key])
      else
        this.combined_error_list.Filter.push('Row no. '+(ind+1)+':' +json_data[key])
    }
  },
  dragitem(event){
    var innerhtml = event.target.innerHTML
    var parser = new DOMParser()
    var doc = parser.parseFromString(innerhtml, "text/xml");
    var table_type = doc.getElementsByTagName('i')[0].getAttribute("title")
    event.dataTransfer.setData("table_name", event.target.innerText);
    event.dataTransfer.setData("table_type", table_type);
  },
  close_join_dialog(closeOnly) {
    if(!closeOnly)
      this.add_join();

    // check is any join is specified or not on that link. If not then trigger remove link evnet to main graph
    // if(!this.join_json[this.lin])
    let current_link_join = _.find(this.join_json,this.link_id)
    if(!current_link_join || !current_link_join[this.link_id]['condition'] || !current_link_join[this.link_id]['condition'].length)
      if(this.selection_graph.getCell(this.link_id))
        this.selection_graph.getCell(this.link_id).remove()
      this.join_dialog = false;
      this.validateStepOnUserAction()
    },
  /* close_date_format_popup(){
    this.dateformat_dialog = false
  }, */
  perform_collapse(action){
    if(action === 'close'){
      if(this.$refs.selection_panel_ref.$el)
        this.$refs.selection_panel_ref.$el.style.width="58%";
      else this.$refs.selection_panel_ref.style.width="58%";
    }
    else{
      if(this.$refs.selection_panel_ref.$el)
        this.$refs.selection_panel_ref.$el.style.width="40%";
      else this.$refs.selection_panel_ref.style.width="40%";
    }
  },
  StepNameChanged(){
    this.stepname = this.stepname.trim()
    if(this.old_stepname.trim() === this.stepname)
      return false
      if(this.stepname.length ===0)
        this.stepname = this.old_stepname.trim()
      else{
      var validated_name = PerformStepNameValidation(this.stepname,this.existingsteplist)
      this.stepname = validated_name
        this.UpdateReferences(this.old_stepname,this.stepname
          ,true)
        this.old_stepname = this.stepname.trim()
        this.savedata(false)    
      }
  },
  performMergeSelection(join_data){
    this.columns_for_selection = []
    if(join_data.length === 0)
      return

    // we are allowing only on join in merge
    var json_key = _.keys(join_data[0])[0]
    var join_obj = join_data[0][json_key]
    var join_conditions = join_obj['condition']

    for (var i = 0; i<join_conditions.length; i++) {
      var row_id =this.get_new_id()
      var alias_name = join_conditions[i].from_column
      for(var j=0;j<this.columns_for_selection.length;j++){
          if(join_conditions[i].from_column == this.columns_for_selection[j].column_name){
            alias_name = this.columns_for_selection[j].column_name+'_'+row_id;
            break;
          }
        }
        var report_alias = this.getMergeColDescription(alias_name,join_obj.jfrom,join_obj.jfrom_drv_table)
        this.columns_for_selection.push({'id':row_id,'column_name': join_conditions[i].from_column, 'table_name': join_obj.jfrom, 'alias_name': alias_name,'is_drv_table':join_obj.jfrom_drv_table,'error_list':'',"report_alias_name":report_alias,"columnDeletionPopUp":false})
      }
      this.callgetColumnsMoreInfo("col_format")
    },
    getMergeColDescription(alias_name,table_name,is_drv_table){
      let key = this.env_id + '_table_column_list';
      var sub_string_index = 0
      if (this.acceleratorObj.acceleration_type === 'jde_ed_e1')
        sub_string_index = 2

      let table_obj = _.find(this.$store.state.cacheData[key],["table_name",table_name])
      var descriptionlist = []
      if(table_obj && table_obj.table_column_descriptions)
        descriptionlist = table_obj.table_column_descriptions

      var description = getColDescription(sub_string_index,alias_name,descriptionlist,is_drv_table)
      if(description && description != DEFAULT_DESCRIPTION)
        return description
      return alias_name

    },
    UpdateReferences(old_data,new_data,is_name_change){

      var update_data ={"step_id":this.parentdigramobj.stepId,"step_name":this.old_stepname}
      var action = ''
      // need to reflect name change.
      if(is_name_change) {
        // if user didnt change any name
        if(old_data === new_data)
          return
        update_data['new_step_name'] = new_data
        action = 'step_name_changed'
      }
      else {
        // First check old_data and new_data same or different. I am not sure this is valid check or not.Because call is comming from watch hook. This hook get called only when any change occurred.
        var is_equal = _.isEqual(old_data, new_data)
        if(is_equal)
          return

        // here using this.parentdigramobj.cell_name because with other steps have old step name not new one. NEED to write logic for the same.
        
        // now check row get added
        if(new_data.length > old_data.length){

          /* If user add any new column, this should get reflect in drv_table order by & selection of archival,purge,copy,report,duplicate step data, if this step is used as driver. */

          // new column always get added at last
          // var new_column_array = _.differenceBy(new_data,old_data,'column_name')
          // var column_names = []
          // for(var i=0;i<new_column_array.length;i++){
          // var new_column = new_column_array[i]
          // var column_name = new_column.column_name
          // //check alias is there . Auto alias get assigned if user added same column again
          // if(new_column.alias_name)
          //   column_name = new_column.alias_name

          // column_names.push(column_name)
          // }
          // update_data['column_names'] = column_names
          // action ="add_columns"
          let new_column_array = []
          for(let i=0;i<new_data.length;i++){
            let column_name = new_data[i].column_name
            let old_obj = _.find(old_data,['column_name',column_name])
            if(old_obj)
              continue
            old_obj = _.cloneDeep(new_data[i])
            old_obj['index'] = i
            new_column_array.push(old_obj)
          }
          update_data['column_names'] = new_column_array
          action ="add_columns"
        }
        // now check row get removed
        else if(new_data.length < old_data.length){

          var removed_columns = _.differenceBy(old_data,new_data,'id')
          action = "columns_removed"
          update_data['columns_removed'] = removed_columns
          update_data['removedColumnIndex'] = this.removedColumnIndex
          // in case of column remove, if columnDeletionType is only_from_current_step then we no need to delete from the dataselection step but we have to delete from archival,purge,copy step.
          update_data['columnDeletionType'] = this.columnDeletionType
        } 
        // check any column alias change
        else{

        }
    }
      if(action){
        this.$emit('update-step-selection',action,update_data)
        let nonGraphEvent = action
        if(action === 'step_name_changed')
          nonGraphEvent = 'step_name_changed_from_inside'

        // if(action === 'columns_removed')
        //   return

        this.$emit('sendNonGraphEvent',nonGraphEvent,{'action_type':nonGraphEvent,'update_details':update_data,"step_id":this.parentdigramobj.stepId})

      }
    },
    onSelectedCols(records){
      this.temp_select_data = records
    },
    JoinAcceleration(jfrom){
      if(!jfrom)
        return
      var start_index = 0
      if(this.acceleratorObj.acceleration_type ==='none')
        return
      if(this.acceleratorObj.acceleration_type === "jde_ed_e1")
        start_index = 2
      // if condition for acceleration type jde/e-business
      // code for jde acceleration
      var jfrom_alias = jfrom.substring(start_index,jfrom.length)
      var jto = _.find(this.jto_column_list,function(obj){
        if(obj && obj.substring(start_index,obj.length) === jfrom_alias)
          return obj
      })
      if(jto)
        this.jto = jto
    },
    callgetColumnsMoreInfo(type_of_request){
      getColumnsMoreInfo(this,this.env_id,this.$session.get("client_id"),this.columns_for_selection,type_of_request,this.sourceCellColumnReferences)
    },
    loadTableDescription(table_list){
        // set timeout for load acceleration details
        let _this = this
        setTimeout(()=>{
          getTableDescriptions(this,table_list,this.env_id).then(response=>{
            // _this.tableDescriptionList = response
            edcAnonymousObj.addTableDescription(response,this.selection_graph)
          }).catch(error=>{

          })
        },1000)
      },
    DescriptionChange(pd_description){
      this.pd_description = pd_description
      this.savedata()
    },
    contextMenuClicked(actionType){
      edcContextMenuObj.contextMenuItemClick(this,actionType,this.selection_graph,this.graphFor)
    },
    mouseOverEvent(obj,condition){
      edcContextMenuObj.mouseOverEvent(this,obj,condition)
    },
    mouseOutEvent(obj){
      edcContextMenuObj.mouseOutEvent(this,obj)
    },
    setLastAlias(item){
      // to trace the alias change store old value in old_alias
      let _this = this
      // settimeout to make sure first changes has been catched in aliasChange() function.User might switch between the alias textboxes. So we have to make sure related aliasChange() function capture the related old name only.
      setTimeout(function(){
        _this.old_alias = item.alias_name
      },100)
    },
    aliasChange(item){
      // Method is for the update alias change
      // 1st check alias changed or not.
      let _this = this
      var old_alias_name = _this.old_alias
      if(item && item.alias_name != old_alias_name){
        // Something has changed.Now emit the event updateReference 
        // first check what happened? there are 3 possibilities. 
        // =>1) previsouly set alias value has changed
        // =>2) previsouly there was no alias but now new alias has set
        // =>3) previsouly there was a alias but now removed it

        let new_alias_value = ''
        let old_alias_value = ''
        if(item.alias_name && old_alias_name){
          new_alias_value = item.alias_name
          old_alias_value = old_alias_name
        } else if(!old_alias_name && item.alias_name){
          new_alias_value = item.alias_name
          old_alias_value = item.column_name
        } else if(old_alias_name && !item.alias_name){
          new_alias_value = item.column_name
          old_alias_value = old_alias_name
        }
        let update_details={"step_id":_this.parentdigramobj.stepId,"step_name":_this.stepname,"old_alias":old_alias_value,"new_alias":new_alias_value,"all_columns":_this.columns_for_selection}
        this.$emit('update-step-selection',"column_alias_change",update_details)
      }
    },
    toggleTablePanel(action){
      this.showFullTablePanel = !this.showFullTablePanel
      if(this.showFullTablePanel)
        this.tableOverlay = 5
      else
        this.tableOverlay = 3
    },
    toggleColumnPanel(action){
      this.showFullColumnPanel = !this.showFullColumnPanel
      if(this.showFullColumnPanel)
        this.columnOverlay = 5
      else
        this.columnOverlay = 2
    },
    preventKeyEvent(event){
        if (event.ctrlKey && (event.code === 'KeyZ'||event.code === 'KeyY'))
          event.preventDefault()
    },
    showLinkDeleteConfirmation(linkView,toolView){
      let message = "Delete join between "+linkView.model.getSourceElement().prop('table_name') +' and ' + linkView.model.getTargetElement().prop('table_name')+'?'
      let confirmFn = function () {
          this.$parent.deleteLinkAfterConfirmation(linkView,toolView)
          }
          let obj = {
            title: 'Delete Link',
            'message': message,
            type: 'info',
            useConfirmBtn: true,
            onConfirm: confirmFn,
            customCloseBtnText:'Cancel',
            customConfirmBtnText:'Delete'
          }
          this.$refs.simplert.openSimplert(obj)
      },
      deleteLinkAfterConfirmation(linkView,toolView){
        linkView.model.remove({ ui: true, tool: toolView.cid });
      },
      saveExpression(expressionObj){
        let row_id = this.get_new_id()
        let aliasName = expressionObj.expression_name
        var data_to_push = {'id':row_id,
        'column_name': expressionObj.expression_name,
         'table_name': expressionObj.table_name, 
         'alias_name': aliasName,
         'is_drv_table':expressionObj.is_drv_table,
         'error_list':'','ref_row_id':'','ref_step_id':'',
         "report_alias_name":aliasName,"columnDeletionPopUp":false,
         "data_type_details":{},
         "isExpression":true,
         "expression_details":expressionObj
        }
        this.columns_for_selection.push(data_to_push)
        this.$emit('sendNonGraphEvent','add_column',{"step_id":this.parentdigramobj.stepId,"column":data_to_push})
        this.showExpressionOpt = false
      },
      editExpression(item){
        
      },
      manageEnvironmentDetails(environmentDetails){
            let datasourceKeys = this.datasourceKeys
            this.dsList = []
            for(let i=0;i<datasourceKeys.length;i++){
                let dsObj = environmentDetails[datasourceKeys[i].key]
                if(!dsObj)
                  continue
                dsObj['dsKeyName'] = datasourceKeys[i].key
                dsObj['displayName'] = dsObj['datasource_name']+' ('+datasourceKeys[i].display+')'
                this.dsList.push(dsObj)
            }
        },
        getAllAvailableReportingTable(){
          var _this = this
          let data = {"client_id":this.$session.get('client_id'),"object_type":"RT"}
          postToServer(_this, config.BUSINESS_OBJECT_URL+'/get_object_names_by_type',data).then(
            response=>{
              this.availableReportingTables = response.result
            }
          ).catch(exception=>{
            // alert(exception)
          })
        }
    },
    
}
</script>
<style scoped>
  .radioClass >>> label{
    top: 4px !important;
}
</style>
<style>

.table th, .table td {
padding:8px !important;
}

.joinGrid{
  max-height: 112px;
  overflow-y: auto;
}
.v-autocomplete.v-input{
  margin:0px;
}


/* .lowerrow{
  display:block;
  height:200px;
  overflow: auto;
  border:1px solid grey;

} */

#mainflex{
  background-color:grey;
}

.list_items {
  draggable:true;
  height:30px;
}

.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
table.v-table thead tr, table.v-table tbody tr{
  height: 24px;
}
.sticky {
  position: absolute;
}
#filter_column_table .v-input__slot{
  margin-bottom: -10px;
  margin-top: -5px;
}
#select_column_table .v-select__slot{
  margin-bottom: -6px;
}
#filter_column_table .v-input__slot input, .v-select-list .v-list__tile__content{
  font-size: 12px;
}

.v-input__slot input, .v-select-list .v-list__tile__content{
  font-size: 12px;
}

#select_column_table td{
  font-size: 12px;
}

tbody {
  overflow-y: scroll !important;
}

#select_column_table .v-input__slot{
  font-size: 14px;
  margin-bottom: -8px;
  margin-top: -5px;
}
#select_column_table .v-input__append-inner i{
  /*font-size: 15px;*/
}
#select_column_table .v-text-field__slot{
  width: 65px;
}
#select_column_table .v-text-field__slot{
  margin-top: -5px;
}
.handle {
  cursor: move !important;
  cursor: -webkit-grabbing !important;
}
.v-radio label{
  font-size: 15px;
}
.v-radio i{
  font-size: 20px;
}
.v-dialog__activator{
  margin-top: -10px;
}
.scrollit {
    overflow:scroll !important;
    height:100px;
}

#togglebtn label{
  margin-bottom:0px !important;
}
/* .highlight_tbl{
  border:3px solid #FF0000 !important;
} */
</style>
// function drop_on_second(event) {
      //   event.preventDefault();
      //   var data = event.dataTransfer.getData("Text");
      //   _this.second_table_name = data
      //   _this.second_table_list = _this.get_columns(data)

      // }

      // function allowDrop_on_second(event) {
      //   event.preventDefault();
      // }

      // function first_table_mouse_over(){
      //   var list = _this.first_table_list
      //   _this.column_desc_table_list = JSON.parse(JSON.stringify(list)).slice(1)
      //   _this.column_desc_table_list
      //   _this.column_desc_of_table_name = _this.column_desc_of_table_name_initial_text + _this.first_table_name
      // }

      // function second_table_mouse_over(){
      //   var list = _this.second_table_list
      //   _this.column_desc_table_list = JSON.parse(JSON.stringify(list)).slice(1)
      //   _this.column_desc_table_list
      //   _this.column_desc_of_table_name = _this.column_desc_of_table_name_initial_text + _this.second_table_name
      // }
